.vRQ81kwpS59HIgGzGZyPB {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 5.12445vw;
  margin-bottom: 1.39092vw; }
  @media (max-width: 1024px) {
    .vRQ81kwpS59HIgGzGZyPB {
      padding-right: 3.84146vw;
      margin-bottom: 1.04268vw; } }
  @media (max-width: 1280px) {
    .vRQ81kwpS59HIgGzGZyPB {
      padding-right: 4.42669vw;
      margin-bottom: 1.20153vw; } }
  @media (max-width: 767px) {
    .vRQ81kwpS59HIgGzGZyPB {
      padding-right: 70px;
      margin-bottom: 19px; } }

._35PVwQjQvYCiDWLuJ9CWqY {
  margin: 0;
  font-family: "Roboto", sans-serif;
  color: #e6e8e6;
  font-size: 1.90337vw;
  line-height: 2.2694vw;
  padding-right: 1.24451vw; }
  @media (max-width: 1024px) {
    ._35PVwQjQvYCiDWLuJ9CWqY {
      font-size: 1.42683vw;
      line-height: 1.70122vw;
      padding-right: 0.93293vw; } }
  @media (max-width: 1280px) {
    ._35PVwQjQvYCiDWLuJ9CWqY {
      font-size: 1.6442vw;
      line-height: 1.96039vw;
      padding-right: 1.07505vw; } }
  @media (max-width: 767px) {
    ._35PVwQjQvYCiDWLuJ9CWqY {
      font-size: 26px;
      line-height: 31px;
      padding-right: 17px; } }

._2gbNlLGSr7HuQ5R_tAt33C {
  background-color: transparent;
  width: 1.83016vw;
  height: 2.2694vw; }
  @media (max-width: 1024px) {
    ._2gbNlLGSr7HuQ5R_tAt33C {
      width: 1.37195vw;
      height: 1.70122vw; } }
  @media (max-width: 1280px) {
    ._2gbNlLGSr7HuQ5R_tAt33C {
      width: 1.58096vw;
      height: 1.96039vw; } }
  @media (max-width: 767px) {
    ._2gbNlLGSr7HuQ5R_tAt33C {
      width: 25px;
      height: 31px; } }
  ._2gbNlLGSr7HuQ5R_tAt33C > svg {
    width: 100%;
    height: 100%; }

._38piuakFAnkguDxafjIk3C {
  list-style-type: none; }
