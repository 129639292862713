._3qkYHYIEUXB2NDU0CXnxj4 {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }

._3RMCsyO9Jy4xLlLTdKVhKO {
  background: #2c2d2c;
  mix-blend-mode: normal;
  opacity: 0.9; }

._3FhvbuDPApYBavwO4ve3bp {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 3.66032vw; }
  @media (max-width: 1024px) {
    ._3FhvbuDPApYBavwO4ve3bp {
      padding-top: 2.7439vw; } }
  @media (max-width: 1280px) {
    ._3FhvbuDPApYBavwO4ve3bp {
      padding-top: 3.16192vw; } }
  @media (max-width: 767px) {
    ._3FhvbuDPApYBavwO4ve3bp {
      padding-top: 50px; } }

._3RC1FfZMLSULmSfNj1eTQk {
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: 500;
  text-align: center;
  color: #5d8c7b;
  line-height: 3.3675vw;
  font-size: 1.90337vw; }
  @media (max-width: 1024px) {
    ._3RC1FfZMLSULmSfNj1eTQk {
      line-height: 2.52439vw;
      font-size: 1.42683vw; } }
  @media (max-width: 1280px) {
    ._3RC1FfZMLSULmSfNj1eTQk {
      line-height: 2.90896vw;
      font-size: 1.6442vw; } }
  @media (max-width: 767px) {
    ._3RC1FfZMLSULmSfNj1eTQk {
      line-height: 46px;
      font-size: 26px; } }

._2JOawPtGcWiyq6aDXwDIaq {
  display: block;
  background: url("/images/checkbox_checked.svg") center no-repeat;
  background-size: contain;
  margin-left: 49%;
  width: 3.66032vw;
  height: 3.66032vw;
  margin-bottom: 1.0981vw; }
  @media (max-width: 1024px) {
    ._2JOawPtGcWiyq6aDXwDIaq {
      width: 2.7439vw;
      height: 2.7439vw;
      margin-bottom: 0.82317vw; } }
  @media (max-width: 1280px) {
    ._2JOawPtGcWiyq6aDXwDIaq {
      width: 3.16192vw;
      height: 3.16192vw;
      margin-bottom: 0.94858vw; } }
  @media (max-width: 767px) {
    ._2JOawPtGcWiyq6aDXwDIaq {
      width: 50px;
      height: 50px;
      margin-bottom: 15px; } }
