@-webkit-keyframes _1eKO935Lizaq45DvdTVUnJ {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes _1eKO935Lizaq45DvdTVUnJ {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.TgH4Dx1ozGHz4JBlIESD9 {
  display: flex;
  align-items: center;
  height: 100%; }

.Wh0qKRPv8nDcIly4BVynq {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 5.85652vw;
  width: 5.85652vw;
  margin-top: 1.46413vw;
  margin-bottom: 1.46413vw; }
  @media (max-width: 1024px) {
    .Wh0qKRPv8nDcIly4BVynq {
      height: 4.39024vw;
      width: 4.39024vw;
      margin-top: 1.09756vw;
      margin-bottom: 1.09756vw; } }
  @media (max-width: 1280px) {
    .Wh0qKRPv8nDcIly4BVynq {
      height: 5.05907vw;
      width: 5.05907vw;
      margin-top: 1.26477vw;
      margin-bottom: 1.26477vw; } }
  @media (max-width: 767px) {
    .Wh0qKRPv8nDcIly4BVynq {
      height: 80px;
      width: 80px;
      margin-top: 20px;
      margin-bottom: 20px; } }

._2BAR9diOIA5OJzBZ8zStQw {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  -webkit-animation: _1eKO935Lizaq45DvdTVUnJ 1s infinite linear;
          animation: _1eKO935Lizaq45DvdTVUnJ 1s infinite linear; }

._3Gt5xbngBgwgjBQOI7Ta4W {
  width: 8.78477vw;
  height: 8.78477vw;
  background-size: 8.78477vw 8.78477vw; }
  @media (max-width: 1024px) {
    ._3Gt5xbngBgwgjBQOI7Ta4W {
      width: 6.58536vw;
      height: 6.58536vw;
      background-size: 6.58536vw 6.58536vw; } }
  @media (max-width: 1280px) {
    ._3Gt5xbngBgwgjBQOI7Ta4W {
      width: 7.5886vw;
      height: 7.5886vw;
      background-size: 7.5886vw 7.5886vw; } }
  @media (max-width: 767px) {
    ._3Gt5xbngBgwgjBQOI7Ta4W {
      width: 120px;
      height: 120px;
      background-size: 120px 120px; } }

._3f8hsQuR3dJxZHM4bXKGmY {
  display: inline-block;
  margin-top: 0;
  width: 1em;
  height: 1em; }
