.WiRRd4Ck1LQcfeKgr0UqT {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 2.19619vw; }
  @media (max-width: 1024px) {
    .WiRRd4Ck1LQcfeKgr0UqT {
      padding-bottom: 1.64634vw; } }
  @media (max-width: 1280px) {
    .WiRRd4Ck1LQcfeKgr0UqT {
      padding-bottom: 1.89715vw; } }
  @media (max-width: 767px) {
    .WiRRd4Ck1LQcfeKgr0UqT {
      padding-bottom: 30px; } }

._25qY_Hw-NOlwNT1VxK3nb9 {
  width: 3.80673vw;
  height: 4.39239vw;
  margin-bottom: 2.19619vw;
  background-size: 3.80673vw 4.39239vw; }
  @media (max-width: 1024px) {
    ._25qY_Hw-NOlwNT1VxK3nb9 {
      width: 2.85366vw;
      height: 3.29268vw;
      margin-bottom: 1.64634vw;
      background-size: 2.85366vw 3.29268vw; } }
  @media (max-width: 1280px) {
    ._25qY_Hw-NOlwNT1VxK3nb9 {
      width: 3.28839vw;
      height: 3.7943vw;
      margin-bottom: 1.89715vw;
      background-size: 3.28839vw 3.7943vw; } }
  @media (max-width: 767px) {
    ._25qY_Hw-NOlwNT1VxK3nb9 {
      width: 52px;
      height: 60px;
      margin-bottom: 30px;
      background-size: 52px 60px; } }

._1X865GltcoB0sa6PKUdKZ4 {
  font-family: "Raleway", sans-serif;
  font-weight: 500;
  color: #5d8c7b;
  text-align: center;
  font-size: 2.04978vw;
  line-height: 3.3675vw; }
  @media (max-width: 1024px) {
    ._1X865GltcoB0sa6PKUdKZ4 {
      font-size: 1.53658vw;
      line-height: 2.52439vw; } }
  @media (max-width: 1280px) {
    ._1X865GltcoB0sa6PKUdKZ4 {
      font-size: 1.77067vw;
      line-height: 2.90896vw; } }
  @media (max-width: 767px) {
    ._1X865GltcoB0sa6PKUdKZ4 {
      font-size: 28px;
      line-height: 46px; } }
