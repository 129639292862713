._2E8gFNgDNfd3i_Wp0cS2Nl {
  background-color: #383638;
  padding: 2.19619vw;
  margin-bottom: 2.19619vw; }
  @media (max-width: 1024px) {
    ._2E8gFNgDNfd3i_Wp0cS2Nl {
      padding: 1.64634vw;
      margin-bottom: 1.64634vw; } }
  @media (max-width: 1280px) {
    ._2E8gFNgDNfd3i_Wp0cS2Nl {
      padding: 1.89715vw;
      margin-bottom: 1.89715vw; } }
  @media (max-width: 767px) {
    ._2E8gFNgDNfd3i_Wp0cS2Nl {
      padding: 30px;
      margin-bottom: 30px; } }

.Waa_bsVfOKTCcDNFNUHQe {
  font-family: "Roboto", sans-serif;
  line-height: 0.85;
  font-weight: 600;
  text-align: left;
  color: #e6e8e6;
  margin-bottom: 2.19619vw;
  font-size: 1.46413vw; }
  @media (max-width: 1024px) {
    .Waa_bsVfOKTCcDNFNUHQe {
      margin-bottom: 1.64634vw;
      font-size: 1.09756vw; } }
  @media (max-width: 1280px) {
    .Waa_bsVfOKTCcDNFNUHQe {
      margin-bottom: 1.89715vw;
      font-size: 1.26477vw; } }
  @media (max-width: 767px) {
    .Waa_bsVfOKTCcDNFNUHQe {
      margin-bottom: 30px;
      font-size: 20px; } }

._3nffMyCgA30kO-LTHJeF6_ {
  color: #35e89f; }
