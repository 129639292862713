._3UnYElCrZJd29mbMoECBuo {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  list-style: none; }

._16g4tpGTfeXTdJVR6GiB9N {
  border: 0.5px solid #368464;
  white-space: nowrap;
  padding: 0.51245vw;
  font-size: 1.1713vw;
  margin-right: 0.73206vw;
  margin-bottom: 0.36603vw; }
  @media (max-width: 1024px) {
    ._16g4tpGTfeXTdJVR6GiB9N {
      padding: 0.38415vw;
      font-size: 0.87805vw;
      margin-right: 0.54878vw;
      margin-bottom: 0.27439vw; } }
  @media (max-width: 1280px) {
    ._16g4tpGTfeXTdJVR6GiB9N {
      padding: 0.44267vw;
      font-size: 1.01181vw;
      margin-right: 0.63238vw;
      margin-bottom: 0.31619vw; } }
  @media (max-width: 767px) {
    ._16g4tpGTfeXTdJVR6GiB9N {
      padding: 7px;
      font-size: 16px;
      margin-right: 10px;
      margin-bottom: 5px; } }

._13KD_ga3Nva4R0OeKKOSVt {
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  color: #35e89f;
  text-transform: uppercase;
  margin-right: 0.36603vw; }
  @media (max-width: 1024px) {
    ._13KD_ga3Nva4R0OeKKOSVt {
      margin-right: 0.27439vw; } }
  @media (max-width: 1280px) {
    ._13KD_ga3Nva4R0OeKKOSVt {
      margin-right: 0.31619vw; } }
  @media (max-width: 767px) {
    ._13KD_ga3Nva4R0OeKKOSVt {
      margin-right: 5px; } }

._2YcrDITgXu_M7O3GwDuio6 {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #e6e8e6; }

._3UMrmLvVx0TmRLn68-1EMo {
  font-family: "Roboto", sans-serif;
  font-weight: 800;
  color: #5d8c7b;
  font-size: 1.24451vw;
  padding: 0.54905vw 0vw; }
  @media (max-width: 1024px) {
    ._3UMrmLvVx0TmRLn68-1EMo {
      font-size: 0.93293vw;
      padding: 0.41159vw 0vw; } }
  @media (max-width: 1280px) {
    ._3UMrmLvVx0TmRLn68-1EMo {
      font-size: 1.07505vw;
      padding: 0.47429vw 0vw; } }
  @media (max-width: 767px) {
    ._3UMrmLvVx0TmRLn68-1EMo {
      font-size: 17px;
      padding: 7.5px 0px; } }

.K9CVsMfIKA-woGgSJM-Zq {
  border-color: #35e89f;
  padding: 0.21962vw 0.51245vw 0.21962vw 0.51245vw;
  margin-right: 0.73206vw;
  margin-bottom: 0.36603vw;
  font-size: 1.0981vw; }
  @media (max-width: 1024px) {
    .K9CVsMfIKA-woGgSJM-Zq {
      padding: 0.16463vw 0.38415vw 0.16463vw 0.38415vw;
      margin-right: 0.54878vw;
      margin-bottom: 0.27439vw;
      font-size: 0.82317vw; } }
  @media (max-width: 1280px) {
    .K9CVsMfIKA-woGgSJM-Zq {
      padding: 0.18972vw 0.44267vw 0.18972vw 0.44267vw;
      margin-right: 0.63238vw;
      margin-bottom: 0.31619vw;
      font-size: 0.94858vw; } }
  @media (max-width: 767px) {
    .K9CVsMfIKA-woGgSJM-Zq {
      padding: 3px 7px 3px 7px;
      margin-right: 10px;
      margin-bottom: 5px;
      font-size: 15px; } }
