._1IW5HQOJ0ull7vTX_HZc_x {
  width: 100%;
  list-style-type: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  height: 4.39239vw;
  margin-bottom: 0.43924vw;
  font-size: 1.90337vw; }
  @media (max-width: 1024px) {
    ._1IW5HQOJ0ull7vTX_HZc_x {
      height: 3.29268vw;
      margin-bottom: 0.32927vw;
      font-size: 1.42683vw; } }
  @media (max-width: 1280px) {
    ._1IW5HQOJ0ull7vTX_HZc_x {
      height: 3.7943vw;
      margin-bottom: 0.37943vw;
      font-size: 1.6442vw; } }
  @media (max-width: 767px) {
    ._1IW5HQOJ0ull7vTX_HZc_x {
      height: 60px;
      margin-bottom: 6px;
      font-size: 26px; } }
  ._1IW5HQOJ0ull7vTX_HZc_x:nth-child(3n + 1) ._3TcTzzZbos78NsCh9gB4Sv {
    width: 7.17423vw; }
    @media (max-width: 1024px) {
      ._1IW5HQOJ0ull7vTX_HZc_x:nth-child(3n + 1) ._3TcTzzZbos78NsCh9gB4Sv {
        width: 5.37805vw; } }
    @media (max-width: 1280px) {
      ._1IW5HQOJ0ull7vTX_HZc_x:nth-child(3n + 1) ._3TcTzzZbos78NsCh9gB4Sv {
        width: 6.19736vw; } }
    @media (max-width: 767px) {
      ._1IW5HQOJ0ull7vTX_HZc_x:nth-child(3n + 1) ._3TcTzzZbos78NsCh9gB4Sv {
        width: 98px; } }
  ._1IW5HQOJ0ull7vTX_HZc_x:nth-child(3n + 1) .RN8aMoh150O_uWASxnFce,
  ._1IW5HQOJ0ull7vTX_HZc_x:nth-child(3n + 1) ._1f9zHGAkNoMDeJiGydC8_X {
    width: 6.14934vw; }
    @media (max-width: 1024px) {
      ._1IW5HQOJ0ull7vTX_HZc_x:nth-child(3n + 1) .RN8aMoh150O_uWASxnFce,
      ._1IW5HQOJ0ull7vTX_HZc_x:nth-child(3n + 1) ._1f9zHGAkNoMDeJiGydC8_X {
        width: 4.60975vw; } }
    @media (max-width: 1280px) {
      ._1IW5HQOJ0ull7vTX_HZc_x:nth-child(3n + 1) .RN8aMoh150O_uWASxnFce,
      ._1IW5HQOJ0ull7vTX_HZc_x:nth-child(3n + 1) ._1f9zHGAkNoMDeJiGydC8_X {
        width: 5.31202vw; } }
    @media (max-width: 767px) {
      ._1IW5HQOJ0ull7vTX_HZc_x:nth-child(3n + 1) .RN8aMoh150O_uWASxnFce,
      ._1IW5HQOJ0ull7vTX_HZc_x:nth-child(3n + 1) ._1f9zHGAkNoMDeJiGydC8_X {
        width: 84px; } }
  ._1IW5HQOJ0ull7vTX_HZc_x:nth-child(3n + 2) ._3TcTzzZbos78NsCh9gB4Sv {
    width: 6.22255vw; }
    @media (max-width: 1024px) {
      ._1IW5HQOJ0ull7vTX_HZc_x:nth-child(3n + 2) ._3TcTzzZbos78NsCh9gB4Sv {
        width: 4.66463vw; } }
    @media (max-width: 1280px) {
      ._1IW5HQOJ0ull7vTX_HZc_x:nth-child(3n + 2) ._3TcTzzZbos78NsCh9gB4Sv {
        width: 5.37526vw; } }
    @media (max-width: 767px) {
      ._1IW5HQOJ0ull7vTX_HZc_x:nth-child(3n + 2) ._3TcTzzZbos78NsCh9gB4Sv {
        width: 85px; } }
  ._1IW5HQOJ0ull7vTX_HZc_x:nth-child(3n + 2) .RN8aMoh150O_uWASxnFce,
  ._1IW5HQOJ0ull7vTX_HZc_x:nth-child(3n + 2) ._1f9zHGAkNoMDeJiGydC8_X {
    width: 5.49048vw; }
    @media (max-width: 1024px) {
      ._1IW5HQOJ0ull7vTX_HZc_x:nth-child(3n + 2) .RN8aMoh150O_uWASxnFce,
      ._1IW5HQOJ0ull7vTX_HZc_x:nth-child(3n + 2) ._1f9zHGAkNoMDeJiGydC8_X {
        width: 4.11585vw; } }
    @media (max-width: 1280px) {
      ._1IW5HQOJ0ull7vTX_HZc_x:nth-child(3n + 2) .RN8aMoh150O_uWASxnFce,
      ._1IW5HQOJ0ull7vTX_HZc_x:nth-child(3n + 2) ._1f9zHGAkNoMDeJiGydC8_X {
        width: 4.74288vw; } }
    @media (max-width: 767px) {
      ._1IW5HQOJ0ull7vTX_HZc_x:nth-child(3n + 2) .RN8aMoh150O_uWASxnFce,
      ._1IW5HQOJ0ull7vTX_HZc_x:nth-child(3n + 2) ._1f9zHGAkNoMDeJiGydC8_X {
        width: 75px; } }
  ._1IW5HQOJ0ull7vTX_HZc_x:nth-child(3n + 3) ._3TcTzzZbos78NsCh9gB4Sv {
    width: 11.20059vw; }
    @media (max-width: 1024px) {
      ._1IW5HQOJ0ull7vTX_HZc_x:nth-child(3n + 3) ._3TcTzzZbos78NsCh9gB4Sv {
        width: 8.39634vw; } }
    @media (max-width: 1280px) {
      ._1IW5HQOJ0ull7vTX_HZc_x:nth-child(3n + 3) ._3TcTzzZbos78NsCh9gB4Sv {
        width: 9.67547vw; } }
    @media (max-width: 767px) {
      ._1IW5HQOJ0ull7vTX_HZc_x:nth-child(3n + 3) ._3TcTzzZbos78NsCh9gB4Sv {
        width: 153px; } }
  ._1IW5HQOJ0ull7vTX_HZc_x:nth-child(3n + 3) .RN8aMoh150O_uWASxnFce,
  ._1IW5HQOJ0ull7vTX_HZc_x:nth-child(3n + 3) ._1f9zHGAkNoMDeJiGydC8_X {
    width: 4.75842vw; }
    @media (max-width: 1024px) {
      ._1IW5HQOJ0ull7vTX_HZc_x:nth-child(3n + 3) .RN8aMoh150O_uWASxnFce,
      ._1IW5HQOJ0ull7vTX_HZc_x:nth-child(3n + 3) ._1f9zHGAkNoMDeJiGydC8_X {
        width: 3.56707vw; } }
    @media (max-width: 1280px) {
      ._1IW5HQOJ0ull7vTX_HZc_x:nth-child(3n + 3) .RN8aMoh150O_uWASxnFce,
      ._1IW5HQOJ0ull7vTX_HZc_x:nth-child(3n + 3) ._1f9zHGAkNoMDeJiGydC8_X {
        width: 4.11049vw; } }
    @media (max-width: 767px) {
      ._1IW5HQOJ0ull7vTX_HZc_x:nth-child(3n + 3) .RN8aMoh150O_uWASxnFce,
      ._1IW5HQOJ0ull7vTX_HZc_x:nth-child(3n + 3) ._1f9zHGAkNoMDeJiGydC8_X {
        width: 65px; } }

.uoo09Px7zpZksHuS1ESfh {
  height: 100%;
  margin-right: 1%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4.39239vw;
  margin-right: 0.43924vw; }
  @media (max-width: 1024px) {
    .uoo09Px7zpZksHuS1ESfh {
      width: 3.29268vw;
      margin-right: 0.32927vw; } }
  @media (max-width: 1280px) {
    .uoo09Px7zpZksHuS1ESfh {
      width: 3.7943vw;
      margin-right: 0.37943vw; } }
  @media (max-width: 767px) {
    .uoo09Px7zpZksHuS1ESfh {
      width: 60px;
      margin-right: 6px; } }
  .uoo09Px7zpZksHuS1ESfh > svg {
    display: inline-block;
    width: 0.96em;
    height: 1.19em;
    vertical-align: middle;
    fill: currentColor; }

._3CpQR3zF_pC3PEU33P8v0J {
  padding: 0 1.72em 0 1.45em;
  height: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  -webkit-transform: translateX(0);
          transform: translateX(0);
  transition: -webkit-transform 0.1s ease;
  transition: transform 0.1s ease;
  transition: transform 0.1s ease, -webkit-transform 0.1s ease;
  border: none;
  outline: none;
  background-color: #5d8c7b;
  color: #e6e8e6; }
  ._3CpQR3zF_pC3PEU33P8v0J:hover, ._3CpQR3zF_pC3PEU33P8v0J:focus {
    -webkit-transform: translateX(0.19em);
            transform: translateX(0.19em);
    background-color: #4f695f; }
  ._3CpQR3zF_pC3PEU33P8v0J:active {
    background-color: #34edaa; }

._1MGjmCnHgb_TyGhyHTmz0K {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #e6e8e6;
  font-size: 1em; }

._3Oo1_GMcbIWSp3TQylGahn {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
  color: #e6e8e6;
  font-size: 1em;
  line-height: 1; }

._2X_cowPqfo_NTrkysdhId9 {
  padding-right: 0.19em; }

._2M8cCjAwhR89FQWzboDyM_ {
  padding-right: 0.19em; }

._2K9ykAUIfqk1zM-YCnP-X1 {
  padding-top: 0.08em;
  font-size: 0.38em;
  line-height: 1; }

._1ivlTJFJ6iRvXk9DJHIZF4 {
  display: inline-block;
  background-color: rgba(230, 232, 230, 0.3);
  border-radius: 20px;
  height: 1.2em; }

._3TcTzzZbos78NsCh9gB4Sv {
  color: transparent;
  -webkit-animation: _1_OXGFxmFBQL6pHJGT-3O9 10s infinite linear;
          animation: _1_OXGFxmFBQL6pHJGT-3O9 10s infinite linear;
  background-image: repeating-linear-gradient(90deg, #86a89b, #86a89b 10%, #5d8c7b 50%, #86a89b 100%);
  background-size: 300px 100%; }

.RN8aMoh150O_uWASxnFce {
  color: transparent; }

._1f9zHGAkNoMDeJiGydC8_X { }

@-webkit-keyframes _1_OXGFxmFBQL6pHJGT-3O9 {
  0% {
    background-position: -300px 0; }
  100% {
    background-position: 300px 0; } }

@keyframes _1_OXGFxmFBQL6pHJGT-3O9 {
  0% {
    background-position: -300px 0; }
  100% {
    background-position: 300px 0; } }
