._2PeNZqrfLBo3R0-ktrS7Yb {
  border: none;
  outline: none;
  background-color: #8f8f8f;
  font-family: "Raleway", sans-serif;
  line-height: 1;
  color: #f2f2f2;
  background-color: #5d8c7b;
  font-size: 1.90337vw;
  padding: 0.87848vw 2.48902vw; }
  @media (max-width: 1024px) {
    ._2PeNZqrfLBo3R0-ktrS7Yb {
      font-size: 1.42683vw;
      padding: 0.65854vw 1.86585vw; } }
  @media (max-width: 1280px) {
    ._2PeNZqrfLBo3R0-ktrS7Yb {
      font-size: 1.6442vw;
      padding: 0.75886vw 2.1501vw; } }
  @media (max-width: 767px) {
    ._2PeNZqrfLBo3R0-ktrS7Yb {
      font-size: 26px;
      padding: 12px 34px; } }
  ._2PeNZqrfLBo3R0-ktrS7Yb:disabled {
    opacity: 0.5;
    cursor: not-allowed; }
  ._2PeNZqrfLBo3R0-ktrS7Yb:focus {
    background-color: #4f695f; }
  ._2PeNZqrfLBo3R0-ktrS7Yb:active {
    color: #2c2d2c;
    background-color: #4dd1a1; }

._3__WsS3_EhecyfCYvRQIxA {
  background-color: #8f8f8f; }
  ._3__WsS3_EhecyfCYvRQIxA:focus {
    background-color: #282a28; }
  ._3__WsS3_EhecyfCYvRQIxA:active {
    color: #2c2d2c;
    background-color: #e6e8e6; }

._3kiA0ky4hfPvqZ_zeq9al4,
._1AxeBJ7rfw-ibvDHQMBJqv {
  background-color: transparent;
  border: 1px solid currentColor; }

._1AxeBJ7rfw-ibvDHQMBJqv {
  color: #f2f2f2; }

._3kiA0ky4hfPvqZ_zeq9al4 {
  color: #383638; }

._3-U-BT_n2DUP5OfmgaEb3d {
  background-color: #383638; }
