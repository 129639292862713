._3P8r7AmbDiL2hszHrd1Pmu {
  display: flex;
  align-items: center; }
  ._3P8r7AmbDiL2hszHrd1Pmu svg {
    width: 1.39092vw;
    height: 1.53734vw; }
    @media (max-width: 1024px) {
      ._3P8r7AmbDiL2hszHrd1Pmu svg {
        width: 1.04268vw;
        height: 1.15244vw; } }
    @media (max-width: 1280px) {
      ._3P8r7AmbDiL2hszHrd1Pmu svg {
        width: 1.20153vw;
        height: 1.32801vw; } }
    @media (max-width: 767px) {
      ._3P8r7AmbDiL2hszHrd1Pmu svg {
        width: 19px;
        height: 21px; } }
