._2oHMmWFTu8_cUCFtSmMj6o {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 999;
  background-color: #2c2d2c; }

._3WFw-IqK7FOMAuAGMM6tKb {
  width: 100%;
  height: 100%; }

._20GcRUeYw17MDi8MVXqqww {
  height: 100%;
  padding-top: 4.17277vw;
  padding-left: 5.12445vw;
  padding-right: 5.12445vw;
  padding-bottom: 2.19619vw; }
  @media (max-width: 1024px) {
    ._20GcRUeYw17MDi8MVXqqww {
      padding-top: 3.12805vw;
      padding-left: 3.84146vw;
      padding-right: 3.84146vw;
      padding-bottom: 1.64634vw; } }
  @media (max-width: 1280px) {
    ._20GcRUeYw17MDi8MVXqqww {
      padding-top: 3.60459vw;
      padding-left: 4.42669vw;
      padding-right: 4.42669vw;
      padding-bottom: 1.89715vw; } }
  @media (max-width: 767px) {
    ._20GcRUeYw17MDi8MVXqqww {
      padding-top: 57px;
      padding-left: 70px;
      padding-right: 70px;
      padding-bottom: 30px; } }

._3FcpXZXDPtEIWb-O5SSo4y {
  position: absolute;
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
  background-color: transparent;
  cursor: pointer;
  z-index: 1;
  width: 1.39092vw;
  height: 1.39092vw;
  top: 1.24451vw;
  right: 2.56223vw;
  transition: fill 0.1s linear;
  fill: #e6e8e6; }
  @media (max-width: 1024px) {
    ._3FcpXZXDPtEIWb-O5SSo4y {
      width: 1.04268vw;
      height: 1.04268vw;
      top: 0.93293vw;
      right: 1.92073vw; } }
  @media (max-width: 1280px) {
    ._3FcpXZXDPtEIWb-O5SSo4y {
      width: 1.20153vw;
      height: 1.20153vw;
      top: 1.07505vw;
      right: 2.21334vw; } }
  @media (max-width: 767px) {
    ._3FcpXZXDPtEIWb-O5SSo4y {
      width: 19px;
      height: 19px;
      top: 17px;
      right: 35px; } }
  ._3FcpXZXDPtEIWb-O5SSo4y:hover {
    fill: rgba(230, 232, 230, 0.4); }
  ._3FcpXZXDPtEIWb-O5SSo4y:active {
    fill: #4dd1a1; }
  ._3FcpXZXDPtEIWb-O5SSo4y > svg {
    fill: inherit;
    color: currentColor;
    display: inline-block;
    vertical-align: middle;
    width: 1.39092vw;
    height: 1.39092vw; }
    @media (max-width: 1024px) {
      ._3FcpXZXDPtEIWb-O5SSo4y > svg {
        width: 1.04268vw;
        height: 1.04268vw; } }
    @media (max-width: 1280px) {
      ._3FcpXZXDPtEIWb-O5SSo4y > svg {
        width: 1.20153vw;
        height: 1.20153vw; } }
    @media (max-width: 767px) {
      ._3FcpXZXDPtEIWb-O5SSo4y > svg {
        width: 19px;
        height: 19px; } }

.bnh6Byp2hjm-xxbrttxpI {
  position: absolute;
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
  fill: #e6e8e6;
  color: #e6e8e6;
  font-family: "Raleway", sans-serif;
  transition: fill 0.1s linear, color 0.1s linear;
  top: 1.24451vw;
  font-size: 1.1713vw; }
  @media (max-width: 1024px) {
    .bnh6Byp2hjm-xxbrttxpI {
      top: 0.93293vw;
      font-size: 0.87805vw; } }
  @media (max-width: 1280px) {
    .bnh6Byp2hjm-xxbrttxpI {
      top: 1.07505vw;
      font-size: 1.01181vw; } }
  @media (max-width: 767px) {
    .bnh6Byp2hjm-xxbrttxpI {
      top: 17px;
      font-size: 16px; } }
  .bnh6Byp2hjm-xxbrttxpI:hover {
    fill: #767876;
    color: #767876; }
  .bnh6Byp2hjm-xxbrttxpI:active {
    fill: #4dd1a1;
    color: #4dd1a1; }
  .bnh6Byp2hjm-xxbrttxpI > svg {
    width: 1.46413vw;
    height: 0.87848vw;
    margin-right: 0.73206vw;
    fill: inherit; }
    @media (max-width: 1024px) {
      .bnh6Byp2hjm-xxbrttxpI > svg {
        width: 1.09756vw;
        height: 0.65854vw;
        margin-right: 0.54878vw; } }
    @media (max-width: 1280px) {
      .bnh6Byp2hjm-xxbrttxpI > svg {
        width: 1.26477vw;
        height: 0.75886vw;
        margin-right: 0.63238vw; } }
    @media (max-width: 767px) {
      .bnh6Byp2hjm-xxbrttxpI > svg {
        width: 20px;
        height: 12px;
        margin-right: 10px; } }
