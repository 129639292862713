._2bFrcOTPR0spVv16yuFBf2 {
  margin: 1.75695vw 0vw 3.14788vw 0vw; }
  @media (max-width: 1024px) {
    ._2bFrcOTPR0spVv16yuFBf2 {
      margin: 1.31707vw 0vw 2.35976vw 0vw; } }
  @media (max-width: 1280px) {
    ._2bFrcOTPR0spVv16yuFBf2 {
      margin: 1.51772vw 0vw 2.71925vw 0vw; } }
  @media (max-width: 767px) {
    ._2bFrcOTPR0spVv16yuFBf2 {
      margin: 24px 0px 43px 0px; } }

._1C7ApeWOM-FcNJJzOJ5xk6 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  list-style: none;
  border-bottom: 2px solid rgba(222, 210, 225, 0.15);
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  border-bottom-width: 0.14641vw; }
  @media (max-width: 1024px) {
    ._1C7ApeWOM-FcNJJzOJ5xk6 {
      border-bottom-width: 0.10976vw; } }
  @media (max-width: 1280px) {
    ._1C7ApeWOM-FcNJJzOJ5xk6 {
      border-bottom-width: 0.12648vw; } }
  @media (max-width: 767px) {
    ._1C7ApeWOM-FcNJJzOJ5xk6 {
      border-bottom-width: 2px; } }
