._2L6jE8hoxrr40IiVscSApE {
  line-height: 0.9615;
  letter-spacing: -3%;
  background-color: rgba(230, 232, 230, 0.1);
  border: 1px solid rgba(230, 232, 230, 0.6);
  font-size: 1.90337vw;
  min-height: 3.73353vw;
  padding: 0.80527vw 1.02489vw; }
  @media (max-width: 1024px) {
    ._2L6jE8hoxrr40IiVscSApE {
      font-size: 1.42683vw;
      min-height: 2.79878vw;
      padding: 0.60366vw 0.76829vw; } }
  @media (max-width: 1280px) {
    ._2L6jE8hoxrr40IiVscSApE {
      font-size: 1.6442vw;
      min-height: 3.22516vw;
      padding: 0.69562vw 0.88534vw; } }
  @media (max-width: 767px) {
    ._2L6jE8hoxrr40IiVscSApE {
      font-size: 26px;
      min-height: 51px;
      padding: 11px 14px; } }
  ._2L6jE8hoxrr40IiVscSApE::-moz-selection {
    background: rgba(52, 237, 170, 0.6); }
  ._2L6jE8hoxrr40IiVscSApE::selection {
    background: rgba(52, 237, 170, 0.6); }

._3aJNcrW9bOt_DEPSHSREli {
  width: 100%; }
