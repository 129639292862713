.zUe23zwpG01VPgah-AkJc {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 5.12445vw;
  margin-bottom: 1.0981vw; }
  @media (max-width: 1024px) {
    .zUe23zwpG01VPgah-AkJc {
      height: 3.84146vw;
      margin-bottom: 0.82317vw; } }
  @media (max-width: 1280px) {
    .zUe23zwpG01VPgah-AkJc {
      height: 4.42669vw;
      margin-bottom: 0.94858vw; } }
  @media (max-width: 767px) {
    .zUe23zwpG01VPgah-AkJc {
      height: 70px;
      margin-bottom: 15px; } }

._2KydjkqD59siEbkkkcc0NJ {
  font-weight: 400;
  font-family: "Raleway", sans-serif;
  color: #e6e8e6;
  font-size: 2.92826vw; }
  @media (max-width: 1024px) {
    ._2KydjkqD59siEbkkkcc0NJ {
      font-size: 2.19512vw; } }
  @media (max-width: 1280px) {
    ._2KydjkqD59siEbkkkcc0NJ {
      font-size: 2.52953vw; } }
  @media (max-width: 767px) {
    ._2KydjkqD59siEbkkkcc0NJ {
      font-size: 40px; } }

.cdXfA4HyBql9vI9_eY9FB {
  list-style-type: none; }

.Z3YHpzvFTZiAXASmkE5Rx {
  height: 100%;
  display: flex;
  flex-direction: column; }

._3FQNzj1hPQR3iJxmmCXzOP {
  display: flex; }

._2XJ6qR1UCQ5LngPYCZ96ZX {
  position: relative;
  width: 5.12445vw;
  height: 2.70864vw; }
  @media (max-width: 1024px) {
    ._2XJ6qR1UCQ5LngPYCZ96ZX {
      width: 3.84146vw;
      height: 2.03049vw; } }
  @media (max-width: 1280px) {
    ._2XJ6qR1UCQ5LngPYCZ96ZX {
      width: 4.42669vw;
      height: 2.33982vw; } }
  @media (max-width: 767px) {
    ._2XJ6qR1UCQ5LngPYCZ96ZX {
      width: 70px;
      height: 37px; } }

._1kdAjUt698Z_qCdaN4b5PI {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  opacity: 0; }
  ._1kdAjUt698Z_qCdaN4b5PI:checked + ._1J-5TEZHA0hlMXR9ziknef {
    color: #2a1c38;
    background-color: #90d6b9; }

._1J-5TEZHA0hlMXR9ziknef {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  cursor: pointer;
  font-weight: 700;
  font-family: "Roboto", sans-serif;
  color: #90d6b9;
  border: 0.5px #90d6b9 solid;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  font-size: 1.46413vw; }
  @media (max-width: 1024px) {
    ._1J-5TEZHA0hlMXR9ziknef {
      font-size: 1.09756vw; } }
  @media (max-width: 1280px) {
    ._1J-5TEZHA0hlMXR9ziknef {
      font-size: 1.26477vw; } }
  @media (max-width: 767px) {
    ._1J-5TEZHA0hlMXR9ziknef {
      font-size: 20px; } }
