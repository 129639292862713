._1bd51b5SUixF6TExW9z2Iq {
  position: fixed;
  display: none;
  visibility: hidden; }
  @media (max-width: 767px) {
    ._1bd51b5SUixF6TExW9z2Iq {
      display: block;
      visibility: visible;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: linear-gradient(to bottom, #344c43 0%, #2c332f 100%);
      z-index: 999; } }
  ._3-keC50sC9tnOIGn4wBl9E {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 3.51391vw; }
    @media (max-width: 1024px) {
      ._3-keC50sC9tnOIGn4wBl9E {
        padding-top: 2.63415vw; } }
    @media (max-width: 1280px) {
      ._3-keC50sC9tnOIGn4wBl9E {
        padding-top: 3.03544vw; } }
    @media (max-width: 767px) {
      ._3-keC50sC9tnOIGn4wBl9E {
        padding-top: 48px; } }
  ._3QSVMJDwot56XRts3QFzpP {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 7.17423vw; }
    @media (max-width: 1024px) {
      ._3QSVMJDwot56XRts3QFzpP {
        margin-bottom: 5.37805vw; } }
    @media (max-width: 1280px) {
      ._3QSVMJDwot56XRts3QFzpP {
        margin-bottom: 6.19736vw; } }
    @media (max-width: 767px) {
      ._3QSVMJDwot56XRts3QFzpP {
        margin-bottom: 98px; } }
  ._3T96pBvxoozdQqOAWZdGLE {
    background-image: url("/images/karaoke_logo.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    opacity: 0.4;
    width: 6.14934vw;
    height: 5.7101vw;
    margin-bottom: 0.65886vw; }
    @media (max-width: 1024px) {
      ._3T96pBvxoozdQqOAWZdGLE {
        width: 4.60975vw;
        height: 4.28049vw;
        margin-bottom: 0.4939vw; } }
    @media (max-width: 1280px) {
      ._3T96pBvxoozdQqOAWZdGLE {
        width: 5.31202vw;
        height: 4.93259vw;
        margin-bottom: 0.56915vw; } }
    @media (max-width: 767px) {
      ._3T96pBvxoozdQqOAWZdGLE {
        width: 84px;
        height: 78px;
        margin-bottom: 9px; } }
  ._1rXYYY9ko7BUksn3UnQawC {
    font-family: "Raleway", sans-serif;
    font-weight: 500;
    color: #ded2e1;
    font-size: 1.31772vw; }
    @media (max-width: 1024px) {
      ._1rXYYY9ko7BUksn3UnQawC {
        font-size: 0.9878vw; } }
    @media (max-width: 1280px) {
      ._1rXYYY9ko7BUksn3UnQawC {
        font-size: 1.13829vw; } }
    @media (max-width: 767px) {
      ._1rXYYY9ko7BUksn3UnQawC {
        font-size: 18px; } }
    ._1nfONitfCbyOUxKgy3U-an {
      font-weight: 100; }
  ._3FAd6J8ZMSObeWXY6Eqy9a {
    padding: 0vw 1.90337vw;
    margin-bottom: 2.12299vw; }
    @media (max-width: 1024px) {
      ._3FAd6J8ZMSObeWXY6Eqy9a {
        padding: 0vw 1.42683vw;
        margin-bottom: 1.59146vw; } }
    @media (max-width: 1280px) {
      ._3FAd6J8ZMSObeWXY6Eqy9a {
        padding: 0vw 1.6442vw;
        margin-bottom: 1.83391vw; } }
    @media (max-width: 767px) {
      ._3FAd6J8ZMSObeWXY6Eqy9a {
        padding: 0px 26px;
        margin-bottom: 29px; } }
  ._2TYKcL2rYwsyuysXy-HRLy {
    font-family: "Raleway", sans-serif;
    font-weight: 300;
    color: #e6e8e6;
    text-align: center;
    font-size: 1.61054vw;
    line-height: 2.41581vw; }
    @media (max-width: 1024px) {
      ._2TYKcL2rYwsyuysXy-HRLy {
        font-size: 1.20732vw;
        line-height: 1.81098vw; } }
    @media (max-width: 1280px) {
      ._2TYKcL2rYwsyuysXy-HRLy {
        font-size: 1.39124vw;
        line-height: 2.08687vw; } }
    @media (max-width: 767px) {
      ._2TYKcL2rYwsyuysXy-HRLy {
        font-size: 22px;
        line-height: 33px; } }
    ._3J2zj3-sB2xhKQzOcij1Dx {
      font-weight: 500;
      color: #35df9a; }
    ._1MpsZ3OSdrQBg3bIdYuWhk {
      font-weight: 100; }
  ._11A25DRaaDRI3beXFxa-B2 {
    background-image: url("/images/in_develop_icon.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 12.29868vw;
    height: 12.29868vw; }
    @media (max-width: 1024px) {
      ._11A25DRaaDRI3beXFxa-B2 {
        width: 9.21951vw;
        height: 9.21951vw; } }
    @media (max-width: 1280px) {
      ._11A25DRaaDRI3beXFxa-B2 {
        width: 10.62404vw;
        height: 10.62404vw; } }
    @media (max-width: 767px) {
      ._11A25DRaaDRI3beXFxa-B2 {
        width: 168px;
        height: 168px; } }
