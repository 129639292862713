._2eLhb25aS2fCge2Yi8JeEo {
  float: right;
  font-family: "Raleway", sans-serif;
  font-weight: 400;
  color: #e6e8e6;
  cursor: pointer;
  background: url("/images/checkbox.svg") center right no-repeat;
  background-size: contain;
  font-size: 1.90337vw;
  margin-top: 0.73206vw;
  line-height: 2.34261vw;
  padding-right: 3.4407vw;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }
  @media (max-width: 1024px) {
    ._2eLhb25aS2fCge2Yi8JeEo {
      font-size: 1.42683vw;
      margin-top: 0.54878vw;
      line-height: 1.7561vw;
      padding-right: 2.57927vw; } }
  @media (max-width: 1280px) {
    ._2eLhb25aS2fCge2Yi8JeEo {
      font-size: 1.6442vw;
      margin-top: 0.63238vw;
      line-height: 2.02363vw;
      padding-right: 2.9722vw; } }
  @media (max-width: 767px) {
    ._2eLhb25aS2fCge2Yi8JeEo {
      font-size: 26px;
      margin-top: 10px;
      line-height: 32px;
      padding-right: 47px; } }

.q8G8vtHnGe20InVgxeYyS {
  background-image: url("/images/checkbox_checked.svg"); }
