.YxrRRTTOS97qwWamlcagw {
  margin-bottom: 1.46413vw; }
  @media (max-width: 1024px) {
    .YxrRRTTOS97qwWamlcagw {
      margin-bottom: 1.09756vw; } }
  @media (max-width: 1280px) {
    .YxrRRTTOS97qwWamlcagw {
      margin-bottom: 1.26477vw; } }
  @media (max-width: 767px) {
    .YxrRRTTOS97qwWamlcagw {
      margin-bottom: 20px; } }
  .YxrRRTTOS97qwWamlcagw:last-child {
    margin-bottom: 0; }

.laWNqgEOgNZlIq-zUCFrZ {
  font-weight: 500;
  font-family: "Raleway", sans-serif;
  text-transform: uppercase;
  color: #e6e8e6;
  font-size: 1.46413vw;
  margin-bottom: 1.0981vw; }
  @media (max-width: 1024px) {
    .laWNqgEOgNZlIq-zUCFrZ {
      font-size: 1.09756vw;
      margin-bottom: 0.82317vw; } }
  @media (max-width: 1280px) {
    .laWNqgEOgNZlIq-zUCFrZ {
      font-size: 1.26477vw;
      margin-bottom: 0.94858vw; } }
  @media (max-width: 767px) {
    .laWNqgEOgNZlIq-zUCFrZ {
      font-size: 20px;
      margin-bottom: 15px; } }

._3d51Nq03PN6oiQrms76wuV {
  min-width: 37.99414vw;
  font-size: 1.53734vw; }
  @media (max-width: 1024px) {
    ._3d51Nq03PN6oiQrms76wuV {
      min-width: 28.4817vw;
      font-size: 1.15244vw; } }
  @media (max-width: 1280px) {
    ._3d51Nq03PN6oiQrms76wuV {
      min-width: 32.82071vw;
      font-size: 1.32801vw; } }
  @media (max-width: 767px) {
    ._3d51Nq03PN6oiQrms76wuV {
      min-width: 519px;
      font-size: 21px; } }

._3StkJSqVUHQl8eEYpDule7 {
  font-size: 1.31772vw; }
  @media (max-width: 1024px) {
    ._3StkJSqVUHQl8eEYpDule7 {
      font-size: 0.9878vw; } }
  @media (max-width: 1280px) {
    ._3StkJSqVUHQl8eEYpDule7 {
      font-size: 1.13829vw; } }
  @media (max-width: 767px) {
    ._3StkJSqVUHQl8eEYpDule7 {
      font-size: 18px; } }

._1US1TVe1CQC6FB2PK2pZkF {
  width: 100%; }

._1Y3X5QYXzWndeOZN6ARptK {
  display: flex;
  flex-direction: row;
  justify-content: flex-end; }

._3Vny17iJTEQ_bNEELBZ1vH {
  position: relative;
  font-weight: 400;
  font-family: "Raleway", sans-serif;
  color: #fff;
  transition: color 0.1s linear;
  background: none;
  border: none;
  padding-right: 1.53734vw;
  font-size: 1.31772vw; }
  @media (max-width: 1024px) {
    ._3Vny17iJTEQ_bNEELBZ1vH {
      padding-right: 1.15244vw;
      font-size: 0.9878vw; } }
  @media (max-width: 1280px) {
    ._3Vny17iJTEQ_bNEELBZ1vH {
      padding-right: 1.32801vw;
      font-size: 1.13829vw; } }
  @media (max-width: 767px) {
    ._3Vny17iJTEQ_bNEELBZ1vH {
      padding-right: 21px;
      font-size: 18px; } }
  ._3Vny17iJTEQ_bNEELBZ1vH:hover {
    color: rgba(230, 232, 230, 0.4); }
    ._3Vny17iJTEQ_bNEELBZ1vH:hover > svg {
      color: rgba(230, 232, 230, 0.4); }
  ._3Vny17iJTEQ_bNEELBZ1vH:focus {
    outline: none; }
  ._3Vny17iJTEQ_bNEELBZ1vH:active {
    color: #4dd1a1; }
    ._3Vny17iJTEQ_bNEELBZ1vH:active > svg {
      color: #4dd1a1; }
  ._3Vny17iJTEQ_bNEELBZ1vH > svg {
    fill: currentColor;
    vertical-align: middle;
    width: 0.65886vw;
    height: 0.65886vw; }
    @media (max-width: 1024px) {
      ._3Vny17iJTEQ_bNEELBZ1vH > svg {
        width: 0.4939vw;
        height: 0.4939vw; } }
    @media (max-width: 1280px) {
      ._3Vny17iJTEQ_bNEELBZ1vH > svg {
        width: 0.56915vw;
        height: 0.56915vw; } }
    @media (max-width: 767px) {
      ._3Vny17iJTEQ_bNEELBZ1vH > svg {
        width: 9px;
        height: 9px; } }
