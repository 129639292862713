._931dKJmFbuDRKjoTUIEO {
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  outline: none;
  border: none;
  color: #e6e8e6;
  height: 1.46413vw;
  width: 2.92826vw; }
  @media (max-width: 1024px) {
    ._931dKJmFbuDRKjoTUIEO {
      height: 1.09756vw;
      width: 2.19512vw; } }
  @media (max-width: 1280px) {
    ._931dKJmFbuDRKjoTUIEO {
      height: 1.26477vw;
      width: 2.52953vw; } }
  @media (max-width: 767px) {
    ._931dKJmFbuDRKjoTUIEO {
      height: 20px;
      width: 40px; } }
  ._931dKJmFbuDRKjoTUIEO:focus {
    color: rgba(230, 232, 230, 0.4); }
  ._931dKJmFbuDRKjoTUIEO:active {
    color: #4dd1a1; }
  ._931dKJmFbuDRKjoTUIEO > svg {
    fill: currentColor;
    height: 1.46413vw; }
    @media (max-width: 1024px) {
      ._931dKJmFbuDRKjoTUIEO > svg {
        height: 1.09756vw; } }
    @media (max-width: 1280px) {
      ._931dKJmFbuDRKjoTUIEO > svg {
        height: 1.26477vw; } }
    @media (max-width: 767px) {
      ._931dKJmFbuDRKjoTUIEO > svg {
        height: 20px; } }
