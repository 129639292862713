._1tqXFt8lp1NwDtmeiCwNe0 {
  top: 100% !important; }

._1tqXFt8lp1NwDtmeiCwNe0._1WoYOX9UktoROdYp9y2YqJ {
  top: 0 !important;
  transition: top 500ms cubic-bezier(0.25, 0.46, 0.45, 0.94); }

._25CT0ZPnMXx16NeoV6amPL {
  top: 0 !important; }

._25CT0ZPnMXx16NeoV6amPL.vj0KIM8H7YkKIkgJqeWxx {
  top: 100% !important;
  transition: top 500ms ease; }
