._3EzkZVRM9scyjEd_c-dZQK {
  margin-top: 3.58712vw;
  background: #383638;
  padding: 2.63543vw 2.92826vw 4.02635vw; }
  @media (max-width: 1024px) {
    ._3EzkZVRM9scyjEd_c-dZQK {
      margin-top: 2.68902vw; } }
  @media (max-width: 1280px) {
    ._3EzkZVRM9scyjEd_c-dZQK {
      margin-top: 3.09868vw; } }
  @media (max-width: 767px) {
    ._3EzkZVRM9scyjEd_c-dZQK {
      margin-top: 49px; } }
  @media (max-width: 1024px) {
    ._3EzkZVRM9scyjEd_c-dZQK {
      padding: 1.97561vw 2.19512vw 3.01829vw; } }
  @media (max-width: 1280px) {
    ._3EzkZVRM9scyjEd_c-dZQK {
      padding: 2.27658vw 2.52953vw 3.47811vw; } }
  @media (max-width: 767px) {
    ._3EzkZVRM9scyjEd_c-dZQK {
      padding: 36px 40px 55px; } }

._1EW5RRDCVFcUUpC_iS5_f {
  margin-bottom: 1.97657vw; }
  @media (max-width: 1024px) {
    ._1EW5RRDCVFcUUpC_iS5_f {
      margin-bottom: 1.48171vw; } }
  @media (max-width: 1280px) {
    ._1EW5RRDCVFcUUpC_iS5_f {
      margin-bottom: 1.70744vw; } }
  @media (max-width: 767px) {
    ._1EW5RRDCVFcUUpC_iS5_f {
      margin-bottom: 27px; } }

._2B9oRfx4-PwMPYPZCs0-e6 {
  margin-bottom: 4.90483vw; }
  @media (max-width: 1024px) {
    ._2B9oRfx4-PwMPYPZCs0-e6 {
      margin-bottom: 3.67683vw; } }
  @media (max-width: 1280px) {
    ._2B9oRfx4-PwMPYPZCs0-e6 {
      margin-bottom: 4.23697vw; } }
  @media (max-width: 767px) {
    ._2B9oRfx4-PwMPYPZCs0-e6 {
      margin-bottom: 67px; } }

._3WFOc_7DnTxstiyn8Oa2bv {
  display: block;
  font-family: Raleway;
  font-style: normal;
  font-weight: 300;
  color: #e6e8e6;
  margin-bottom: 0.80527vw;
  font-size: 1.31772vw;
  line-height: 1.61054vw; }
  @media (max-width: 1024px) {
    ._3WFOc_7DnTxstiyn8Oa2bv {
      margin-bottom: 0.60366vw;
      font-size: 0.9878vw;
      line-height: 1.20732vw; } }
  @media (max-width: 1280px) {
    ._3WFOc_7DnTxstiyn8Oa2bv {
      margin-bottom: 0.69562vw;
      font-size: 1.13829vw;
      line-height: 1.39124vw; } }
  @media (max-width: 767px) {
    ._3WFOc_7DnTxstiyn8Oa2bv {
      margin-bottom: 11px;
      font-size: 18px;
      line-height: 22px; } }

._3Azq0dwbb7GBRbO8Xqi0Rb {
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  letter-spacing: -0.03em;
  color: #e6e8e6;
  font-size: 1.90337vw;
  line-height: 1.83016vw; }
  @media (max-width: 1024px) {
    ._3Azq0dwbb7GBRbO8Xqi0Rb {
      font-size: 1.42683vw;
      line-height: 1.37195vw; } }
  @media (max-width: 1280px) {
    ._3Azq0dwbb7GBRbO8Xqi0Rb {
      font-size: 1.6442vw;
      line-height: 1.58096vw; } }
  @media (max-width: 767px) {
    ._3Azq0dwbb7GBRbO8Xqi0Rb {
      font-size: 26px;
      line-height: 25px; } }

._1bNrtCKMlvJkhO1zZn2HIN {
  font-family: Raleway;
  font-weight: 600;
  letter-spacing: -0.03em; }
