.lDOb7IkI4A7YA4jiXrV1E {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  font-family: "Raleway", sans-serif;
  font-weight: 600;
  color: #e6e8e6;
  font-size: 1.0981vw;
  line-height: 1.24451vw;
  margin-bottom: 0.65886vw; }
  @media (max-width: 1024px) {
    .lDOb7IkI4A7YA4jiXrV1E {
      font-size: 0.82317vw;
      line-height: 0.93293vw;
      margin-bottom: 0.4939vw; } }
  @media (max-width: 1280px) {
    .lDOb7IkI4A7YA4jiXrV1E {
      font-size: 0.94858vw;
      line-height: 1.07505vw;
      margin-bottom: 0.56915vw; } }
  @media (max-width: 767px) {
    .lDOb7IkI4A7YA4jiXrV1E {
      font-size: 15px;
      line-height: 17px;
      margin-bottom: 9px; } }
  .lDOb7IkI4A7YA4jiXrV1E:last-child {
    margin-bottom: 1.83016vw; }
    @media (max-width: 1024px) {
      .lDOb7IkI4A7YA4jiXrV1E:last-child {
        margin-bottom: 1.37195vw; } }
    @media (max-width: 1280px) {
      .lDOb7IkI4A7YA4jiXrV1E:last-child {
        margin-bottom: 1.58096vw; } }
    @media (max-width: 767px) {
      .lDOb7IkI4A7YA4jiXrV1E:last-child {
        margin-bottom: 25px; } }

._3cmuc7IqO2y5-47tPo7xsP {
  width: 25%; }

._3SVAvtUVZA9mq9Vzzs6bh2 {
  width: 75%;
  font-weight: 300;
  text-align: justify;
  line-height: 1.2; }

.nfU1LYRVaS-dwVzG4b8Hy {
  position: relative;
  margin-right: -1em;
  padding-right: 1em;
  max-height: 2.4em;
  overflow: hidden; }
  .nfU1LYRVaS-dwVzG4b8Hy:before {
    content: '\2026';
    position: absolute;
    right: 0.8em;
    bottom: 0;
    display: inline-block;
    background-color: #383638; }
  .nfU1LYRVaS-dwVzG4b8Hy:after {
    content: '';
    position: absolute;
    right: 0.8em;
    margin-top: 0.2em;
    width: 1em;
    height: 1em;
    background-color: #383638; }
