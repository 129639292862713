._1jJZty5WODd-ts_kbgmXAj {
  width: 100%;
  background-color: #383638;
  font-weight: 300;
  color: #e6e8e6;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.73206vw;
  padding: 1.53734vw 2.92826vw 2.12299vw;
  font-size: 1.90337vw; }
  @media (max-width: 1024px) {
    ._1jJZty5WODd-ts_kbgmXAj {
      margin-bottom: 0.54878vw;
      padding: 1.15244vw 2.19512vw 1.59146vw;
      font-size: 1.42683vw; } }
  @media (max-width: 1280px) {
    ._1jJZty5WODd-ts_kbgmXAj {
      margin-bottom: 0.63238vw;
      padding: 1.32801vw 2.52953vw 1.83391vw;
      font-size: 1.6442vw; } }
  @media (max-width: 767px) {
    ._1jJZty5WODd-ts_kbgmXAj {
      margin-bottom: 10px;
      padding: 21px 40px 29px;
      font-size: 26px; } }
  ._1jJZty5WODd-ts_kbgmXAj:hover {
    background-color: #454345; }
    ._1jJZty5WODd-ts_kbgmXAj:hover ._1SmUNYBP9F1eZqoPyn2ZOd {
      visibility: visible; }
  ._1jJZty5WODd-ts_kbgmXAj:last-child {
    margin-bottom: 0; }

._1SmUNYBP9F1eZqoPyn2ZOd {
  visibility: hidden; }

._2pzoziAVkkbegHPUffB9dM {
  color: #34edaa;
  font-weight: bold; }

._1Y6DdZf4IaFbiOAOEM3rQe {
  margin-bottom: 1.31772vw; }
  @media (max-width: 1024px) {
    ._1Y6DdZf4IaFbiOAOEM3rQe {
      margin-bottom: 0.9878vw; } }
  @media (max-width: 1280px) {
    ._1Y6DdZf4IaFbiOAOEM3rQe {
      margin-bottom: 1.13829vw; } }
  @media (max-width: 767px) {
    ._1Y6DdZf4IaFbiOAOEM3rQe {
      margin-bottom: 18px; } }
  ._1Y6DdZf4IaFbiOAOEM3rQe:last-child {
    margin-bottom: 0; }

._1m1NImrp-xBb-BiNG2WPUP {
  margin: 0; }
