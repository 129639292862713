._1Dm8-3anh4ytl3_1IHbXgN {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #2c2d2c;
  color: #e6e8e6; }

._1xBVs8MdOvGDq8xs5SjeVe {
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: 300;
  text-align: center;
  text-shadow: 1px 2px 4px rgba(0, 0, 0, 0.25);
  margin-bottom: 3.29429vw;
  max-width: 59.73646vw;
  font-size: 2.92826vw;
  line-height: 3.95315vw; }
  @media (max-width: 1024px) {
    ._1xBVs8MdOvGDq8xs5SjeVe {
      margin-bottom: 2.46951vw;
      max-width: 44.78048vw;
      font-size: 2.19512vw;
      line-height: 2.96341vw; } }
  @media (max-width: 1280px) {
    ._1xBVs8MdOvGDq8xs5SjeVe {
      margin-bottom: 2.84573vw;
      max-width: 51.6025vw;
      font-size: 2.52953vw;
      line-height: 3.41487vw; } }
  @media (max-width: 767px) {
    ._1xBVs8MdOvGDq8xs5SjeVe {
      margin-bottom: 45px;
      max-width: 816px;
      font-size: 40px;
      line-height: 54px; } }

._1t8tO4RR4NRWQrc605sMO3 {
  padding: 0.95168vw 3.29429vw; }
  @media (max-width: 1024px) {
    ._1t8tO4RR4NRWQrc605sMO3 {
      padding: 0.71341vw 2.46951vw; } }
  @media (max-width: 1280px) {
    ._1t8tO4RR4NRWQrc605sMO3 {
      padding: 0.8221vw 2.84573vw; } }
  @media (max-width: 767px) {
    ._1t8tO4RR4NRWQrc605sMO3 {
      padding: 13px 45px; } }
