._1x6SYajfM1yLFplVosLUu- {
  height: 100%;
  width: 100%;
  background-color: #444444; }
  ._2lmgCD1ABk8jGrD48AavRU {
    content: '';
    display: block;
    position: fixed;
    width: 100%;
    height: 100%;
    opacity: 0.15;
    -webkit-filter: grayscale(100%);
            filter: grayscale(100%); }
  ._3ISXF9-0UDwI76w-FdtkAZ {
    content: '';
    display: block;
    position: fixed;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    transition: opacity 1s ease-in-out;
    opacity: 1; }
  ._2NtWUqi1TrIED5-mIYUS5D {
    transition: opacity 1s ease-in-out;
    opacity: 1; }
  ._1T8-SNA0xp31qv8WsTfIuc {
    opacity: 0; }
  ._1ojAgIbLQjAVki9VYOZwb- {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    background-color: rgba(51, 51, 51, 0.8); }
    ._1ojAgIbLQjAVki9VYOZwb-:before {
      content: '';
      display: block;
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: linear-gradient(90deg, rgba(63, 64, 63, 0.6) -10.6%, rgba(63, 64, 63, 0.6) 43.01%, rgba(63, 64, 63, 0) 78.16%);
      mix-blend-mode: soft-light; }
  ._3lb3FdrSbyeAE-dGTSQw8R {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    padding-top: 4.97804vw; }
    @media (max-width: 1024px) {
      ._3lb3FdrSbyeAE-dGTSQw8R {
        padding-top: 3.73171vw; } }
    @media (max-width: 1280px) {
      ._3lb3FdrSbyeAE-dGTSQw8R {
        padding-top: 4.30021vw; } }
    @media (max-width: 767px) {
      ._3lb3FdrSbyeAE-dGTSQw8R {
        padding-top: 68px; } }
  ._1r-uZXqBZt7XXFq6NLIqf8 {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    z-index: 1;
    padding: 0vw 3.66032vw;
    margin-bottom: 2.92826vw; }
    @media (max-width: 1024px) {
      ._1r-uZXqBZt7XXFq6NLIqf8 {
        padding: 0vw 2.7439vw;
        margin-bottom: 2.19512vw; } }
    @media (max-width: 1280px) {
      ._1r-uZXqBZt7XXFq6NLIqf8 {
        padding: 0vw 3.16192vw;
        margin-bottom: 2.52953vw; } }
    @media (max-width: 767px) {
      ._1r-uZXqBZt7XXFq6NLIqf8 {
        padding: 0px 50px;
        margin-bottom: 40px; } }

._3zqUV0rnLO-nWUk20Z6gQ0 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
  padding: 0.58565vw 1.0981vw 0vw 1.0981vw; }
  @media (max-width: 1024px) {
    ._3zqUV0rnLO-nWUk20Z6gQ0 {
      padding: 0.43902vw 0.82317vw 0vw 0.82317vw; } }
  @media (max-width: 1280px) {
    ._3zqUV0rnLO-nWUk20Z6gQ0 {
      padding: 0.50591vw 0.94858vw 0vw 0.94858vw; } }
  @media (max-width: 767px) {
    ._3zqUV0rnLO-nWUk20Z6gQ0 {
      padding: 8px 15px 0px 15px; } }
  ._2_Bb2hbG5MsJ5MuWrRB6WY {
    background-image: url("/images/karaoke_logo.svg");
    background-repeat: no-repeat;
    background-position: center;
    width: 1.97657vw;
    height: 1.83016vw;
    margin-right: 1.1713vw;
    background-size: 1.97657vw 1.83016vw; }
    @media (max-width: 1024px) {
      ._2_Bb2hbG5MsJ5MuWrRB6WY {
        width: 1.48171vw;
        height: 1.37195vw;
        margin-right: 0.87805vw;
        background-size: 1.48171vw 1.37195vw; } }
    @media (max-width: 1280px) {
      ._2_Bb2hbG5MsJ5MuWrRB6WY {
        width: 1.70744vw;
        height: 1.58096vw;
        margin-right: 1.01181vw;
        background-size: 1.70744vw 1.58096vw; } }
    @media (max-width: 767px) {
      ._2_Bb2hbG5MsJ5MuWrRB6WY {
        width: 27px;
        height: 25px;
        margin-right: 16px;
        background-size: 27px 25px; } }
  ._2PTCm0E0E97Xihgc9qRPMe {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-family: "Raleway", sans-serif;
    font-weight: 500;
    font-size: 1.75695vw;
    color: #e6e8e6; }
    @media (max-width: 1024px) {
      ._2PTCm0E0E97Xihgc9qRPMe {
        font-size: 1.31707vw; } }
    @media (max-width: 1280px) {
      ._2PTCm0E0E97Xihgc9qRPMe {
        font-size: 1.51772vw; } }
    @media (max-width: 767px) {
      ._2PTCm0E0E97Xihgc9qRPMe {
        font-size: 24px; } }
    ._2_pF16PEQn00lvowGuRKYh {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      cursor: pointer;
      color: inherit; }
      ._2_pF16PEQn00lvowGuRKYh:hover, ._2_pF16PEQn00lvowGuRKYh:focus {
        color: inherit;
        text-decoration: none; }
    ._1KorZ_hNBa-ctybLv2Wq3e {
      font-weight: 100;
      text-transform: lowercase; }
    ._38LI8PrfK0FV50mDtcHAqu {
      font-weight: 100;
      padding: 0vw 0.21962vw; }
      @media (max-width: 1024px) {
        ._38LI8PrfK0FV50mDtcHAqu {
          padding: 0vw 0.16463vw; } }
      @media (max-width: 1280px) {
        ._38LI8PrfK0FV50mDtcHAqu {
          padding: 0vw 0.18972vw; } }
      @media (max-width: 767px) {
        ._38LI8PrfK0FV50mDtcHAqu {
          padding: 0px 3px; } }

._1EwbgGqp2qgRX3b6-V_8eG {
  opacity: 0 !important; }

._1EwbgGqp2qgRX3b6-V_8eG.-BMM7MDSg_Xh3IeDCJMT0 {
  opacity: 1 !important;
  transition: opacity 0.1 linear; }

._11h-BqILDgJVbxmwTrd8d_ {
  opacity: 1 !important; }

._11h-BqILDgJVbxmwTrd8d_._1zk8ezOBJZbQYUN7w4lgVN {
  opacity: 0 !important;
  transition: opacity 0.1 linear; }

._1Nf143CwW51gzK4YbBWtal {
  width: 100%; }

._3zoTarSsG_NY2DH7lT4MBD {
  display: inline-block;
  border-radius: 20px;
  color: transparent;
  background-color: rgba(230, 232, 230, 0.3);
  width: 175px;
  height: 19px; }
