._3cdCGCzt-siVNl06P8zuTH {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  margin-top: 2.19619vw;
  padding: 2.56223vw 2.92826vw 3.66032vw;
  background: #383638; }
  @media (max-width: 1024px) {
    ._3cdCGCzt-siVNl06P8zuTH {
      margin-top: 1.64634vw;
      padding: 1.92073vw 2.19512vw 2.7439vw; } }
  @media (max-width: 1280px) {
    ._3cdCGCzt-siVNl06P8zuTH {
      margin-top: 1.89715vw;
      padding: 2.21334vw 2.52953vw 3.16192vw; } }
  @media (max-width: 767px) {
    ._3cdCGCzt-siVNl06P8zuTH {
      margin-top: 30px;
      padding: 35px 40px 50px; } }
  ._3cdCGCzt-siVNl06P8zuTH > span {
    font-family: Raleway;
    font-style: normal;
    font-weight: 300;
    line-height: 24px;
    font-size: 18px;
    font-size: 1.31772vw;
    color: #e6e8e6; }
    @media (max-width: 1024px) {
      ._3cdCGCzt-siVNl06P8zuTH > span {
        font-size: 0.9878vw; } }
    @media (max-width: 1280px) {
      ._3cdCGCzt-siVNl06P8zuTH > span {
        font-size: 1.13829vw; } }
    @media (max-width: 767px) {
      ._3cdCGCzt-siVNl06P8zuTH > span {
        font-size: 18px; } }
  ._3cdCGCzt-siVNl06P8zuTH > h3 {
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    line-height: 25px;
    letter-spacing: -0.03em;
    font-size: 1.90337vw;
    margin-bottom: 2.56223vw;
    color: #e6e8e6; }
    @media (max-width: 1024px) {
      ._3cdCGCzt-siVNl06P8zuTH > h3 {
        font-size: 1.42683vw;
        margin-bottom: 1.92073vw; } }
    @media (max-width: 1280px) {
      ._3cdCGCzt-siVNl06P8zuTH > h3 {
        font-size: 1.6442vw;
        margin-bottom: 2.21334vw; } }
    @media (max-width: 767px) {
      ._3cdCGCzt-siVNl06P8zuTH > h3 {
        font-size: 26px;
        margin-bottom: 35px; } }
  ._3cdCGCzt-siVNl06P8zuTH ._1tsaCSGYhVm2mEkWUwaitu span {
    display: block;
    font-family: Raleway;
    font-style: normal;
    font-weight: 300;
    font-size: 1.31772vw;
    line-height: 1.75695vw;
    margin-bottom: 0.51245vw;
    color: #e6e8e6; }
    @media (max-width: 1024px) {
      ._3cdCGCzt-siVNl06P8zuTH ._1tsaCSGYhVm2mEkWUwaitu span {
        font-size: 0.9878vw;
        line-height: 1.31707vw;
        margin-bottom: 0.38415vw; } }
    @media (max-width: 1280px) {
      ._3cdCGCzt-siVNl06P8zuTH ._1tsaCSGYhVm2mEkWUwaitu span {
        font-size: 1.13829vw;
        line-height: 1.51772vw;
        margin-bottom: 0.44267vw; } }
    @media (max-width: 767px) {
      ._3cdCGCzt-siVNl06P8zuTH ._1tsaCSGYhVm2mEkWUwaitu span {
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 7px; } }
  ._3cdCGCzt-siVNl06P8zuTH ._1tsaCSGYhVm2mEkWUwaitu h3 {
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    letter-spacing: -0.03em;
    line-height: 1.83016vw;
    font-size: 1.90337vw;
    margin-bottom: 2.56223vw;
    color: #e6e8e6; }
    @media (max-width: 1024px) {
      ._3cdCGCzt-siVNl06P8zuTH ._1tsaCSGYhVm2mEkWUwaitu h3 {
        line-height: 1.37195vw;
        font-size: 1.42683vw;
        margin-bottom: 1.92073vw; } }
    @media (max-width: 1280px) {
      ._3cdCGCzt-siVNl06P8zuTH ._1tsaCSGYhVm2mEkWUwaitu h3 {
        line-height: 1.58096vw;
        font-size: 1.6442vw;
        margin-bottom: 2.21334vw; } }
    @media (max-width: 767px) {
      ._3cdCGCzt-siVNl06P8zuTH ._1tsaCSGYhVm2mEkWUwaitu h3 {
        line-height: 25px;
        font-size: 26px;
        margin-bottom: 35px; } }

._1FtPdGCRyEEVOTLbx2pS7B p {
  font-family: Raleway;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  font-size: 1.31772vw;
  margin-bottom: 0.51245vw;
  color: #e6e8e6; }
  @media (max-width: 1024px) {
    ._1FtPdGCRyEEVOTLbx2pS7B p {
      font-size: 0.9878vw;
      margin-bottom: 0.38415vw; } }
  @media (max-width: 1280px) {
    ._1FtPdGCRyEEVOTLbx2pS7B p {
      font-size: 1.13829vw;
      margin-bottom: 0.44267vw; } }
  @media (max-width: 767px) {
    ._1FtPdGCRyEEVOTLbx2pS7B p {
      font-size: 18px;
      margin-bottom: 7px; } }

._1FtPdGCRyEEVOTLbx2pS7B input {
  background-color: transparent;
  outline: none;
  color: #e6e8e6;
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  letter-spacing: -0.03em;
  width: 44%;
  font-size: 1.83016vw;
  line-height: 1.83016vw;
  padding: 1.1713vw 0.87848vw;
  color: #e6e8e6;
  border: 1px solid rgba(230, 232, 230, 0.2); }
  @media (max-width: 1024px) {
    ._1FtPdGCRyEEVOTLbx2pS7B input {
      font-size: 1.37195vw;
      line-height: 1.37195vw;
      padding: 0.87805vw 0.65854vw; } }
  @media (max-width: 1280px) {
    ._1FtPdGCRyEEVOTLbx2pS7B input {
      font-size: 1.58096vw;
      line-height: 1.58096vw;
      padding: 1.01181vw 0.75886vw; } }
  @media (max-width: 767px) {
    ._1FtPdGCRyEEVOTLbx2pS7B input {
      font-size: 25px;
      line-height: 25px;
      padding: 16px 12px; } }

._1FtPdGCRyEEVOTLbx2pS7B ._1EA6iDfkD3VZpgALw7LDm0 {
  position: absolute;
  left: 40%;
  width: 33px;
  height: 18px;
  cursor: pointer;
  background: url("/images/show_password.svg") center no-repeat;
  background-size: 2.41581vw 1.31772vw; }
  @media (max-width: 1024px) {
    ._1FtPdGCRyEEVOTLbx2pS7B ._1EA6iDfkD3VZpgALw7LDm0 {
      background-size: 1.81098vw 0.9878vw; } }
  @media (max-width: 1280px) {
    ._1FtPdGCRyEEVOTLbx2pS7B ._1EA6iDfkD3VZpgALw7LDm0 {
      background-size: 2.08687vw 1.13829vw; } }
  @media (max-width: 767px) {
    ._1FtPdGCRyEEVOTLbx2pS7B ._1EA6iDfkD3VZpgALw7LDm0 {
      background-size: 33px 18px; } }

._1FtPdGCRyEEVOTLbx2pS7B ._1c7ld05tUTvBFiKKZDuCHN {
  display: flex;
  align-items: center;
  position: relative; }

._1FtPdGCRyEEVOTLbx2pS7B ._2e0ESA015lNhuCqWDNXeJn {
  width: 28px;
  height: 28px;
  background: url("/images/apply.svg") center no-repeat;
  margin-left: 1.75695vw; }
  @media (max-width: 1024px) {
    ._1FtPdGCRyEEVOTLbx2pS7B ._2e0ESA015lNhuCqWDNXeJn {
      margin-left: 1.31707vw; } }
  @media (max-width: 1280px) {
    ._1FtPdGCRyEEVOTLbx2pS7B ._2e0ESA015lNhuCqWDNXeJn {
      margin-left: 1.51772vw; } }
  @media (max-width: 767px) {
    ._1FtPdGCRyEEVOTLbx2pS7B ._2e0ESA015lNhuCqWDNXeJn {
      margin-left: 24px; } }

._1FtPdGCRyEEVOTLbx2pS7B ._3xmR2b8bBGc02t5s9jmu9F {
  position: relative;
  background: #9c6152;
  border: 1px solid #fa5a33;
  color: #e6e8e6;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-left: 10px;
  padding: 9px 16px; }

._1FtPdGCRyEEVOTLbx2pS7B ._3xmR2b8bBGc02t5s9jmu9F:after,
._1FtPdGCRyEEVOTLbx2pS7B ._3xmR2b8bBGc02t5s9jmu9F:before {
  right: 100%;
  top: 50%;
  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none; }

._1FtPdGCRyEEVOTLbx2pS7B ._3xmR2b8bBGc02t5s9jmu9F:after {
  border-color: rgba(136, 183, 213, 0);
  border-right-color: #9c6152;
  border-width: 7px;
  margin-top: -7px; }

._1FtPdGCRyEEVOTLbx2pS7B ._3xmR2b8bBGc02t5s9jmu9F:before {
  border-color: rgba(194, 225, 245, 0);
  border-right-color: #fa5a33;
  border-width: 8px;
  margin-top: -8px; }

.vbKsEFEAjN5a1uH07KytK {
  font-family: Raleway;
  font-style: normal;
  font-weight: normal;
  line-height: 26px;
  text-align: center;
  cursor: pointer;
  color: #f2f2f2;
  font-size: 1.61054vw;
  margin-left: 4.83163vw; }
  @media (max-width: 1024px) {
    .vbKsEFEAjN5a1uH07KytK {
      font-size: 1.20732vw;
      margin-left: 3.62195vw; } }
  @media (max-width: 1280px) {
    .vbKsEFEAjN5a1uH07KytK {
      font-size: 1.39124vw;
      margin-left: 4.17373vw; } }
  @media (max-width: 767px) {
    .vbKsEFEAjN5a1uH07KytK {
      font-size: 22px;
      margin-left: 66px; } }
  .vbKsEFEAjN5a1uH07KytK:hover {
    text-decoration: underline; }

._2f673aIreMIWphjrWl1e-_ {
  padding-top: 0.73206vw; }
  @media (max-width: 1024px) {
    ._2f673aIreMIWphjrWl1e-_ {
      padding-top: 0.54878vw; } }
  @media (max-width: 1280px) {
    ._2f673aIreMIWphjrWl1e-_ {
      padding-top: 0.63238vw; } }
  @media (max-width: 767px) {
    ._2f673aIreMIWphjrWl1e-_ {
      padding-top: 10px; } }
  ._2f673aIreMIWphjrWl1e-_ ._3xJwJgJHQW0xwE0B7XMh1L {
    font-family: Raleway;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    font-size: 2.56223vw;
    margin-bottom: 1.97657vw;
    color: #e6e8e6; }
    @media (max-width: 1024px) {
      ._2f673aIreMIWphjrWl1e-_ ._3xJwJgJHQW0xwE0B7XMh1L {
        font-size: 1.92073vw;
        margin-bottom: 1.48171vw; } }
    @media (max-width: 1280px) {
      ._2f673aIreMIWphjrWl1e-_ ._3xJwJgJHQW0xwE0B7XMh1L {
        font-size: 2.21334vw;
        margin-bottom: 1.70744vw; } }
    @media (max-width: 767px) {
      ._2f673aIreMIWphjrWl1e-_ ._3xJwJgJHQW0xwE0B7XMh1L {
        font-size: 35px;
        margin-bottom: 27px; } }
  ._2f673aIreMIWphjrWl1e-_ ._1FtPdGCRyEEVOTLbx2pS7B input {
    width: 44%; }
    ._2f673aIreMIWphjrWl1e-_ ._1FtPdGCRyEEVOTLbx2pS7B input._2fxNza8j5Zd5utAEi3bA51 {
      border: 1px solid rgba(250, 90, 51, 0.8); }
    ._2f673aIreMIWphjrWl1e-_ ._1FtPdGCRyEEVOTLbx2pS7B input._34yZaavHNd6YNYGepAjCDm {
      border: 1px solid rgba(53, 232, 159, 0.6); }
  ._2f673aIreMIWphjrWl1e-_ .vSMWRGbovIp3V5HAYqJXs {
    width: 44%;
    display: flex;
    justify-content: flex-end;
    margin-top: 1.83016vw; }
    @media (max-width: 1024px) {
      ._2f673aIreMIWphjrWl1e-_ .vSMWRGbovIp3V5HAYqJXs {
        margin-top: 1.37195vw; } }
    @media (max-width: 1280px) {
      ._2f673aIreMIWphjrWl1e-_ .vSMWRGbovIp3V5HAYqJXs {
        margin-top: 1.58096vw; } }
    @media (max-width: 767px) {
      ._2f673aIreMIWphjrWl1e-_ .vSMWRGbovIp3V5HAYqJXs {
        margin-top: 25px; } }
    ._2f673aIreMIWphjrWl1e-_ .vSMWRGbovIp3V5HAYqJXs > button:last-child {
      margin-left: 1.24451vw; }
      @media (max-width: 1024px) {
        ._2f673aIreMIWphjrWl1e-_ .vSMWRGbovIp3V5HAYqJXs > button:last-child {
          margin-left: 0.93293vw; } }
      @media (max-width: 1280px) {
        ._2f673aIreMIWphjrWl1e-_ .vSMWRGbovIp3V5HAYqJXs > button:last-child {
          margin-left: 1.07505vw; } }
      @media (max-width: 767px) {
        ._2f673aIreMIWphjrWl1e-_ .vSMWRGbovIp3V5HAYqJXs > button:last-child {
          margin-left: 17px; } }
  ._2f673aIreMIWphjrWl1e-_ ._1FtPdGCRyEEVOTLbx2pS7B {
    margin-bottom: 1.97657vw; }
    @media (max-width: 1024px) {
      ._2f673aIreMIWphjrWl1e-_ ._1FtPdGCRyEEVOTLbx2pS7B {
        margin-bottom: 1.48171vw; } }
    @media (max-width: 1280px) {
      ._2f673aIreMIWphjrWl1e-_ ._1FtPdGCRyEEVOTLbx2pS7B {
        margin-bottom: 1.70744vw; } }
    @media (max-width: 767px) {
      ._2f673aIreMIWphjrWl1e-_ ._1FtPdGCRyEEVOTLbx2pS7B {
        margin-bottom: 27px; } }
