.BnU9t64MqC1nX-46IKtJF {
  text-align: left;
  max-width: 100%;
  display: inline-block;
  padding: 0;
  margin-bottom: 1.0981vw; }
  @media (max-width: 1024px) {
    .BnU9t64MqC1nX-46IKtJF {
      margin-bottom: 0.82317vw; } }
  @media (max-width: 1280px) {
    .BnU9t64MqC1nX-46IKtJF {
      margin-bottom: 0.94858vw; } }
  @media (max-width: 767px) {
    .BnU9t64MqC1nX-46IKtJF {
      margin-bottom: 15px; } }

._3bVTXI3REwqEqV_YtcEW0U {
  position: relative;
  color: #e6e8e6;
  transition: color 0.1s linear;
  background: none;
  border: none;
  outline: none; }
  ._3bVTXI3REwqEqV_YtcEW0U:hover {
    color: #35e89f; }
    ._3bVTXI3REwqEqV_YtcEW0U:hover ._165BWLD3IAS7oXzR0-TDdq > span, ._3bVTXI3REwqEqV_YtcEW0U:hover .FBj1KiS0zbk2UhdsFhmrV > span {
      border-bottom: 1.5px solid #35e89f; }
    ._3bVTXI3REwqEqV_YtcEW0U:hover:before {
      background-color: #35e89f; }
    ._3bVTXI3REwqEqV_YtcEW0U:hover span {
      color: #35e89f; }

._165BWLD3IAS7oXzR0-TDdq {
  display: inline-block;
  max-width: 100%;
  font-family: "Raleway", sans-serif;
  font-weight: 500;
  color: #e6e8e6;
  font-size: 1.90337vw;
  line-height: 2.48902vw; }
  @media (max-width: 1024px) {
    ._165BWLD3IAS7oXzR0-TDdq {
      font-size: 1.42683vw;
      line-height: 1.86585vw; } }
  @media (max-width: 1280px) {
    ._165BWLD3IAS7oXzR0-TDdq {
      font-size: 1.6442vw;
      line-height: 2.1501vw; } }
  @media (max-width: 767px) {
    ._165BWLD3IAS7oXzR0-TDdq {
      font-size: 26px;
      line-height: 34px; } }

.FBj1KiS0zbk2UhdsFhmrV {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

._2gUf54by00i_2sjM5ZgqHd {
  font-style: italic;
  font-weight: 300; }
  ._2gUf54by00i_2sjM5ZgqHd:not(:empty) {
    padding-left: 0.43924vw; }
    @media (max-width: 1024px) {
      ._2gUf54by00i_2sjM5ZgqHd:not(:empty) {
        padding-left: 0.32927vw; } }
    @media (max-width: 1280px) {
      ._2gUf54by00i_2sjM5ZgqHd:not(:empty) {
        padding-left: 0.37943vw; } }
    @media (max-width: 767px) {
      ._2gUf54by00i_2sjM5ZgqHd:not(:empty) {
        padding-left: 6px; } }
