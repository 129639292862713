._9D1LAUyT8VbOPMgI6OSE5 {
  padding-bottom: 20px; }

._1KT-2neLEclwuUNemgmN_G {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 1.90337vw;
  margin-bottom: 1.31772vw;
  margin-top: 4.5388vw;
  color: #e6e8e6; }
  @media (max-width: 1024px) {
    ._1KT-2neLEclwuUNemgmN_G {
      font-size: 1.42683vw;
      margin-bottom: 0.9878vw;
      margin-top: 3.40244vw; } }
  @media (max-width: 1280px) {
    ._1KT-2neLEclwuUNemgmN_G {
      font-size: 1.6442vw;
      margin-bottom: 1.13829vw;
      margin-top: 3.92078vw; } }
  @media (max-width: 767px) {
    ._1KT-2neLEclwuUNemgmN_G {
      font-size: 26px;
      margin-bottom: 18px;
      margin-top: 62px; } }

._1ioCiwXJQ6ac622tPu6-Qc {
  min-width: 10.2489vw; }
  @media (max-width: 1024px) {
    ._1ioCiwXJQ6ac622tPu6-Qc {
      min-width: 7.68292vw; } }
  @media (max-width: 1280px) {
    ._1ioCiwXJQ6ac622tPu6-Qc {
      min-width: 8.85337vw; } }
  @media (max-width: 767px) {
    ._1ioCiwXJQ6ac622tPu6-Qc {
      min-width: 140px; } }

.ciAYRHw7UTuKDnvGcTW-_ {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  flex-grow: 1;
  padding-top: 7.39385vw; }
  @media (max-width: 1024px) {
    .ciAYRHw7UTuKDnvGcTW-_ {
      padding-top: 5.54268vw; } }
  @media (max-width: 1280px) {
    .ciAYRHw7UTuKDnvGcTW-_ {
      padding-top: 6.38707vw; } }
  @media (max-width: 767px) {
    .ciAYRHw7UTuKDnvGcTW-_ {
      padding-top: 101px; } }
