._1KHEgmPlzSUXCf96TyAbRF {
  padding-top: 1.46413vw;
  padding-bottom: 1.46413vw; }
  @media (max-width: 1024px) {
    ._1KHEgmPlzSUXCf96TyAbRF {
      padding-top: 1.09756vw;
      padding-bottom: 1.09756vw; } }
  @media (max-width: 1280px) {
    ._1KHEgmPlzSUXCf96TyAbRF {
      padding-top: 1.26477vw;
      padding-bottom: 1.26477vw; } }
  @media (max-width: 767px) {
    ._1KHEgmPlzSUXCf96TyAbRF {
      padding-top: 20px;
      padding-bottom: 20px; } }

.yzmnaEkmLoprE6xUFrUex {
  height: 75px !important;
  display: flex !important;
  align-items: center !important;
  margin-top: 3.07467vw; }
  @media (max-width: 1024px) {
    .yzmnaEkmLoprE6xUFrUex {
      margin-top: 2.30488vw; } }
  @media (max-width: 1280px) {
    .yzmnaEkmLoprE6xUFrUex {
      margin-top: 2.65601vw; } }
  @media (max-width: 767px) {
    .yzmnaEkmLoprE6xUFrUex {
      margin-top: 42px; } }

._3ckHK9xsfi9HLlofmgBGcH {
  font-family: Raleway;
  font-style: normal;
  font-weight: 300;
  line-height: 25px;
  letter-spacing: -0.03em;
  font-size: 2.92826vw;
  color: #e6e8e6; }
  @media (max-width: 1024px) {
    ._3ckHK9xsfi9HLlofmgBGcH {
      font-size: 2.19512vw; } }
  @media (max-width: 1280px) {
    ._3ckHK9xsfi9HLlofmgBGcH {
      font-size: 2.52953vw; } }
  @media (max-width: 767px) {
    ._3ckHK9xsfi9HLlofmgBGcH {
      font-size: 40px; } }

._2LL8SQeMZWSpcdeC3YD-KE {
  background: none;
  color: #fff;
  border: 1px solid currentColor;
  font-family: Raleway;
  font-style: normal;
  font-weight: normal;
  cursor: pointer;
  min-width: 15.59297vw;
  font-size: 1.90337vw;
  line-height: 1.90337vw;
  padding: 0.95168vw 1.83016vw;
  color: #f2f2f2; }
  @media (max-width: 1024px) {
    ._2LL8SQeMZWSpcdeC3YD-KE {
      min-width: 11.68902vw;
      font-size: 1.42683vw;
      line-height: 1.42683vw;
      padding: 0.71341vw 1.37195vw; } }
  @media (max-width: 1280px) {
    ._2LL8SQeMZWSpcdeC3YD-KE {
      min-width: 13.46977vw;
      font-size: 1.6442vw;
      line-height: 1.6442vw;
      padding: 0.8221vw 1.58096vw; } }
  @media (max-width: 767px) {
    ._2LL8SQeMZWSpcdeC3YD-KE {
      min-width: 213px;
      font-size: 26px;
      line-height: 26px;
      padding: 13px 25px; } }
  ._2LL8SQeMZWSpcdeC3YD-KE:hover {
    color: rgba(255, 255, 255, 0.5); }
  ._2LL8SQeMZWSpcdeC3YD-KE:focus, ._2LL8SQeMZWSpcdeC3YD-KE:active {
    color: #35e89f; }
