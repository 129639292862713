.e96WhXLeIlWhxVd__WACM {
  padding-left: 5.85652vw;
  padding-right: 5.85652vw; }
  @media (max-width: 1024px) {
    .e96WhXLeIlWhxVd__WACM {
      padding-left: 4.39024vw;
      padding-right: 4.39024vw; } }
  @media (max-width: 1280px) {
    .e96WhXLeIlWhxVd__WACM {
      padding-left: 5.05907vw;
      padding-right: 5.05907vw; } }
  @media (max-width: 767px) {
    .e96WhXLeIlWhxVd__WACM {
      padding-left: 80px;
      padding-right: 80px; } }

.fazIW8_S6ctKXRDcXd1I0 > option {
  background-color: #689b88; }
