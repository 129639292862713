.jWh9Ip-nJA1odpU3YHh47 {
  position: relative;
  flex-grow: 1;
  height: auto; }
  @media (max-width: 1024px) {
    .jWh9Ip-nJA1odpU3YHh47 {
      height: auto; } }
  @media (max-width: 1280px) {
    .jWh9Ip-nJA1odpU3YHh47 {
      height: auto; } }
  @media (max-width: 767px) {
    .jWh9Ip-nJA1odpU3YHh47 {
      height: auto; } }
  .jWh9Ip-nJA1odpU3YHh47:before {
    content: '';
    display: block;
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(180deg, rgba(3, 38, 47, 0.8) 13.37%, rgba(3, 38, 47, 0) 69.52%, rgba(3, 38, 47, 0) 119.25%);
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    top: 4.39239vw; }
    @media (max-width: 1024px) {
      .jWh9Ip-nJA1odpU3YHh47:before {
        top: 3.29268vw; } }
    @media (max-width: 1280px) {
      .jWh9Ip-nJA1odpU3YHh47:before {
        top: 3.7943vw; } }
    @media (max-width: 767px) {
      .jWh9Ip-nJA1odpU3YHh47:before {
        top: 60px; } }

._3jTAjBSsabBKY0-i22uO-k {
  width: 100%;
  position: absolute;
  bottom: 0;
  transition: all 0.3s ease;
  padding-bottom: 0vw; }
  @media (max-width: 1024px) {
    ._3jTAjBSsabBKY0-i22uO-k {
      padding-bottom: 0vw; } }
  @media (max-width: 1280px) {
    ._3jTAjBSsabBKY0-i22uO-k {
      padding-bottom: 0vw; } }
  @media (max-width: 767px) {
    ._3jTAjBSsabBKY0-i22uO-k {
      padding-bottom: 0px; } }
  ._3jTAjBSsabBKY0-i22uO-k:hover {
    margin-bottom: 3.4407vw; }
    ._3jTAjBSsabBKY0-i22uO-k:hover ._24brT0NJK3Er9wsqFU2IiB {
      bottom: 0; }
    @media (max-width: 1024px) {
      ._3jTAjBSsabBKY0-i22uO-k:hover {
        margin-bottom: 2.57927vw; } }
    @media (max-width: 1280px) {
      ._3jTAjBSsabBKY0-i22uO-k:hover {
        margin-bottom: 2.9722vw; } }
    @media (max-width: 767px) {
      ._3jTAjBSsabBKY0-i22uO-k:hover {
        margin-bottom: 47px; } }

._2PABy-LAb6CBZc5tEjCeeB { }
  ._2PABy-LAb6CBZc5tEjCeeB:hover {
    margin-bottom: 0vw; }
    @media (max-width: 1024px) {
      ._2PABy-LAb6CBZc5tEjCeeB:hover {
        margin-bottom: 0vw; } }
    @media (max-width: 1280px) {
      ._2PABy-LAb6CBZc5tEjCeeB:hover {
        margin-bottom: 0vw; } }
    @media (max-width: 767px) {
      ._2PABy-LAb6CBZc5tEjCeeB:hover {
        margin-bottom: 0px; } }
  ._2PABy-LAb6CBZc5tEjCeeB > .GF6Bu8VoqYXCz0BLfXGRE {
    -webkit-filter: blur(5px);
            filter: blur(5px); }

._24brT0NJK3Er9wsqFU2IiB {
  position: fixed;
  z-index: 2;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 120%;
  background-color: #2c2d2c;
  transition: bottom 0.3s ease;
  height: 3.4407vw;
  bottom: -3.4407vw; }
  @media (max-width: 1024px) {
    ._24brT0NJK3Er9wsqFU2IiB {
      height: 2.57927vw;
      bottom: -2.57927vw; } }
  @media (max-width: 1280px) {
    ._24brT0NJK3Er9wsqFU2IiB {
      height: 2.9722vw;
      bottom: -2.9722vw; } }
  @media (max-width: 767px) {
    ._24brT0NJK3Er9wsqFU2IiB {
      height: 47px;
      bottom: -47px; } }

.f93aunPg1tToO36a_a2GU {
  font-family: "Roboto", sans-serif;
  font-weight: 800;
  color: #e6e8e6;
  cursor: default;
  flex-grow: 1;
  text-align: left;
  -webkit-transform: translateX(-14%);
          transform: translateX(-14%);
  font-size: 1.46413vw; }
  .f93aunPg1tToO36a_a2GU:first-child, .f93aunPg1tToO36a_a2GU:last-child {
    visibility: hidden; }
  @media (max-width: 1024px) {
    .f93aunPg1tToO36a_a2GU {
      font-size: 1.09756vw; } }
  @media (max-width: 1280px) {
    .f93aunPg1tToO36a_a2GU {
      font-size: 1.26477vw; } }
  @media (max-width: 767px) {
    .f93aunPg1tToO36a_a2GU {
      font-size: 20px; } }

._1DPtaa6bsUraXjQS5UVbuz {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 1;
  color: #e6e8e6;
  font-size: 2.92826vw;
  width: 36.60322vw;
  line-height: 1.35;
  text-align: center; }
  @media (max-width: 1024px) {
    ._1DPtaa6bsUraXjQS5UVbuz {
      font-size: 2.19512vw;
      width: 27.43902vw; } }
  @media (max-width: 1280px) {
    ._1DPtaa6bsUraXjQS5UVbuz {
      font-size: 2.52953vw;
      width: 31.61918vw; } }
  @media (max-width: 767px) {
    ._1DPtaa6bsUraXjQS5UVbuz {
      font-size: 40px;
      width: 500px; } }

.tsfB6CwTCZfcvRQzEkvSo {
  position: absolute;
  width: 100%;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  opacity: 0.5;
  height: 25.98829vw; }
  @media (max-width: 1024px) {
    .tsfB6CwTCZfcvRQzEkvSo {
      height: 19.4817vw; } }
  @media (max-width: 1280px) {
    .tsfB6CwTCZfcvRQzEkvSo {
      height: 22.44962vw; } }
  @media (max-width: 767px) {
    .tsfB6CwTCZfcvRQzEkvSo {
      height: 355px; } }

._2uj_t11SAH9a0CuDpzjnfs {
  position: relative;
  content: '';
  display: block;
  width: 1px;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 40%, #5b9477 90%, #44b889 100%); }

.ByDZIX5N2OyfY-_LtdJB_ {
  pointer-events: none;
  position: absolute;
  box-sizing: border-box;
  background-color: #e6e8e6;
  z-index: 3;
  min-width: 13.6896vw;
  padding: 0.65886vw 1.83016vw 0.80527vw 1.1713vw; }
  @media (max-width: 1024px) {
    .ByDZIX5N2OyfY-_LtdJB_ {
      min-width: 10.26219vw;
      padding: 0.4939vw 1.37195vw 0.60366vw 0.87805vw; } }
  @media (max-width: 1280px) {
    .ByDZIX5N2OyfY-_LtdJB_ {
      min-width: 11.82557vw;
      padding: 0.56915vw 1.58096vw 0.69562vw 1.01181vw; } }
  @media (max-width: 767px) {
    .ByDZIX5N2OyfY-_LtdJB_ {
      min-width: 187px;
      padding: 9px 25px 11px 16px; } }

._1dRzakUq5E_4UfFiR9SSwh {
  margin: 0;
  font-family: "Roboto", sans-serif;
  line-height: 1.06;
  color: #27233c;
  margin-bottom: 0.36603vw;
  font-size: 1.1713vw; }
  @media (max-width: 1024px) {
    ._1dRzakUq5E_4UfFiR9SSwh {
      margin-bottom: 0.27439vw;
      font-size: 0.87805vw; } }
  @media (max-width: 1280px) {
    ._1dRzakUq5E_4UfFiR9SSwh {
      margin-bottom: 0.31619vw;
      font-size: 1.01181vw; } }
  @media (max-width: 767px) {
    ._1dRzakUq5E_4UfFiR9SSwh {
      margin-bottom: 5px;
      font-size: 16px; } }

._3FPmQLRiUpWIKfd951vBro {
  font-family: "Roboto", sans-serif;
  line-height: 1.3;
  color: #352f37;
  font-size: 1.46413vw;
  margin-bottom: 0.36603vw; }
  @media (max-width: 1024px) {
    ._3FPmQLRiUpWIKfd951vBro {
      font-size: 1.09756vw;
      margin-bottom: 0.27439vw; } }
  @media (max-width: 1280px) {
    ._3FPmQLRiUpWIKfd951vBro {
      font-size: 1.26477vw;
      margin-bottom: 0.31619vw; } }
  @media (max-width: 767px) {
    ._3FPmQLRiUpWIKfd951vBro {
      font-size: 20px;
      margin-bottom: 5px; } }
  ._3FPmQLRiUpWIKfd951vBro:before {
    content: '';
    display: inline-block;
    background: #39ab5b;
    vertical-align: middle;
    mix-blend-mode: normal;
    border-radius: 50%;
    margin-right: 0.95168vw;
    margin-bottom: 0.36603vw;
    width: 0.73206vw;
    height: 0.73206vw;
    font-size: 1.46413vw; }
    @media (max-width: 1024px) {
      ._3FPmQLRiUpWIKfd951vBro:before {
        margin-right: 0.71341vw;
        margin-bottom: 0.27439vw;
        width: 0.54878vw;
        height: 0.54878vw;
        font-size: 1.09756vw; } }
    @media (max-width: 1280px) {
      ._3FPmQLRiUpWIKfd951vBro:before {
        margin-right: 0.8221vw;
        margin-bottom: 0.31619vw;
        width: 0.63238vw;
        height: 0.63238vw;
        font-size: 1.26477vw; } }
    @media (max-width: 767px) {
      ._3FPmQLRiUpWIKfd951vBro:before {
        margin-right: 13px;
        margin-bottom: 5px;
        width: 10px;
        height: 10px;
        font-size: 20px; } }

.c60LPi9_IQ0WFqULdInl8 {
  margin: 0;
  display: flex;
  align-items: flex-start;
  margin-left: 1.68375vw; }
  @media (max-width: 1024px) {
    .c60LPi9_IQ0WFqULdInl8 {
      margin-left: 1.26219vw; } }
  @media (max-width: 1280px) {
    .c60LPi9_IQ0WFqULdInl8 {
      margin-left: 1.45448vw; } }
  @media (max-width: 767px) {
    .c60LPi9_IQ0WFqULdInl8 {
      margin-left: 23px; } }

._3qHrIKvLGjJ5cXzWLVsECs,
._2pC9zOsf7H_qiSGSMpPXwu,
.e_tmq1x-i_j2QYEHIs7xO {
  font-family: "Roboto", sans-serif;
  color: #27233c; }

._3qHrIKvLGjJ5cXzWLVsECs {
  font-size: 0.58565vw; }
  @media (max-width: 1024px) {
    ._3qHrIKvLGjJ5cXzWLVsECs {
      font-size: 0.43902vw; } }
  @media (max-width: 1280px) {
    ._3qHrIKvLGjJ5cXzWLVsECs {
      font-size: 0.50591vw; } }
  @media (max-width: 767px) {
    ._3qHrIKvLGjJ5cXzWLVsECs {
      font-size: 8px; } }

._2pC9zOsf7H_qiSGSMpPXwu {
  font-weight: 300;
  line-height: 1;
  font-size: 1.46413vw; }
  @media (max-width: 1024px) {
    ._2pC9zOsf7H_qiSGSMpPXwu {
      font-size: 1.09756vw; } }
  @media (max-width: 1280px) {
    ._2pC9zOsf7H_qiSGSMpPXwu {
      font-size: 1.26477vw; } }
  @media (max-width: 767px) {
    ._2pC9zOsf7H_qiSGSMpPXwu {
      font-size: 20px; } }

.e_tmq1x-i_j2QYEHIs7xO {
  font-weight: 300;
  font-size: 0.87848vw; }
  @media (max-width: 1024px) {
    .e_tmq1x-i_j2QYEHIs7xO {
      font-size: 0.65854vw; } }
  @media (max-width: 1280px) {
    .e_tmq1x-i_j2QYEHIs7xO {
      font-size: 0.75886vw; } }
  @media (max-width: 767px) {
    .e_tmq1x-i_j2QYEHIs7xO {
      font-size: 12px; } }

.GF6Bu8VoqYXCz0BLfXGRE {
  position: relative;
  z-index: 2;
  -webkit-transform: translateY(6px);
          transform: translateY(6px); }
