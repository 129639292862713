.SktTRbI_5UtSonRmqR5cQ {
  position: relative;
  display: flex;
  flex-direction: row;
  background-color: #426b5c;
  background: linear-gradient(to right, #426b5c 0%, #374b43 100%);
  color: #e6e8e6;
  padding: 2.04978vw 1.53734vw 2.41581vw 1.83016vw;
  min-height: 10.83455vw;
  max-height: 13.90922vw; }
  @media (max-width: 1024px) {
    .SktTRbI_5UtSonRmqR5cQ {
      padding: 1.53658vw 1.15244vw 1.81098vw 1.37195vw;
      min-height: 8.12195vw;
      max-height: 10.42683vw; } }
  @media (max-width: 1280px) {
    .SktTRbI_5UtSonRmqR5cQ {
      padding: 1.77067vw 1.32801vw 2.08687vw 1.58096vw;
      min-height: 9.35928vw;
      max-height: 12.01529vw; } }
  @media (max-width: 767px) {
    .SktTRbI_5UtSonRmqR5cQ {
      padding: 28px 21px 33px 25px;
      min-height: 148px;
      max-height: 190px; } }
  .SktTRbI_5UtSonRmqR5cQ ._1Qq58tU1l8TMVG7_wSPm4a {
    flex-grow: 1; }

._2FHG4sb5rbTpaEDxLQC7GI {
  max-height: none; }
  ._2FHG4sb5rbTpaEDxLQC7GI ._1Qq58tU1l8TMVG7_wSPm4a span {
    white-space: normal; }

._2cTQx2W9z3sFDqnjSX-Fqq {
  display: flex;
  justify-content: center;
  align-items: center;
  transition: fill 0.1s linear;
  width: 1.1713vw;
  height: 0.58565vw;
  fill: inherit; }
  @media (max-width: 1024px) {
    ._2cTQx2W9z3sFDqnjSX-Fqq {
      width: 0.87805vw;
      height: 0.43902vw; } }
  @media (max-width: 1280px) {
    ._2cTQx2W9z3sFDqnjSX-Fqq {
      width: 1.01181vw;
      height: 0.50591vw; } }
  @media (max-width: 767px) {
    ._2cTQx2W9z3sFDqnjSX-Fqq {
      width: 16px;
      height: 8px; } }

._1KvoXx4mc83VIU2HwOemNQ {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg); }

._2MBZCz2BXhYKi28TjriXx3 {
  display: flex;
  flex-direction: column;
  width: 26.93997vw;
  padding: 0.36603vw 5.85652vw 0vw 0vw; }
  @media (max-width: 1024px) {
    ._2MBZCz2BXhYKi28TjriXx3 {
      width: 20.19512vw;
      padding: 0.27439vw 4.39024vw 0vw 0vw; } }
  @media (max-width: 1280px) {
    ._2MBZCz2BXhYKi28TjriXx3 {
      width: 23.27172vw;
      padding: 0.31619vw 5.05907vw 0vw 0vw; } }
  @media (max-width: 767px) {
    ._2MBZCz2BXhYKi28TjriXx3 {
      width: 368px;
      padding: 5px 80px 0px 0px; } }

._1PooPv-ESBJYrWTJInmDy- {
  font-family: "Raleway", sans-serif;
  font-weight: 300;
  letter-spacing: -0.03em;
  color: #e6e8e6;
  font-size: 1.90337vw;
  margin-bottom: 1.0981vw; }
  @media (max-width: 1024px) {
    ._1PooPv-ESBJYrWTJInmDy- {
      font-size: 1.42683vw;
      margin-bottom: 0.82317vw; } }
  @media (max-width: 1280px) {
    ._1PooPv-ESBJYrWTJInmDy- {
      font-size: 1.6442vw;
      margin-bottom: 0.94858vw; } }
  @media (max-width: 767px) {
    ._1PooPv-ESBJYrWTJInmDy- {
      font-size: 26px;
      margin-bottom: 15px; } }

._1Qq58tU1l8TMVG7_wSPm4a {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 23.64568vw; }
  @media (max-width: 1024px) {
    ._1Qq58tU1l8TMVG7_wSPm4a {
      width: 17.72561vw; } }
  @media (max-width: 1280px) {
    ._1Qq58tU1l8TMVG7_wSPm4a {
      width: 20.42599vw; } }
  @media (max-width: 767px) {
    ._1Qq58tU1l8TMVG7_wSPm4a {
      width: 323px; } }

.haf7-cbL3MQi9vOQCIBcH {
  font-family: "Raleway", sans-serif;
  font-weight: 500;
  color: #e6e8e6;
  transition: color 0.1s linear;
  fill: inherit;
  font-size: 0.73206vw;
  padding-right: 0.58565vw; }
  @media (max-width: 1024px) {
    .haf7-cbL3MQi9vOQCIBcH {
      font-size: 0.54878vw;
      padding-right: 0.43902vw; } }
  @media (max-width: 1280px) {
    .haf7-cbL3MQi9vOQCIBcH {
      font-size: 0.63238vw;
      padding-right: 0.50591vw; } }
  @media (max-width: 767px) {
    .haf7-cbL3MQi9vOQCIBcH {
      font-size: 10px;
      padding-right: 8px; } }

._1ReQNxrD51qR3NFdDR0aF9 {
  padding: 0;
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  bottom: 1.0981vw;
  right: 1.1713vw;
  background: none;
  border: none;
  outline: none;
  fill: #e6e8e6; }
  @media (max-width: 1024px) {
    ._1ReQNxrD51qR3NFdDR0aF9 {
      bottom: 0.82317vw;
      right: 0.87805vw; } }
  @media (max-width: 1280px) {
    ._1ReQNxrD51qR3NFdDR0aF9 {
      bottom: 0.94858vw;
      right: 1.01181vw; } }
  @media (max-width: 767px) {
    ._1ReQNxrD51qR3NFdDR0aF9 {
      bottom: 15px;
      right: 16px; } }
  ._1ReQNxrD51qR3NFdDR0aF9:hover {
    fill: rgba(230, 232, 230, 0.4);
    color: rgba(230, 232, 230, 0.4); }
  ._1ReQNxrD51qR3NFdDR0aF9:active {
    fill: #4dd1a1;
    color: #4dd1a1; }
