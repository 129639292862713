._1SiDcbV3ipSVLu4eqo3NXV {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  flex-grow: 1;
  padding-top: 4.17277vw; }
  @media (max-width: 1024px) {
    ._1SiDcbV3ipSVLu4eqo3NXV {
      padding-top: 3.12805vw; } }
  @media (max-width: 1280px) {
    ._1SiDcbV3ipSVLu4eqo3NXV {
      padding-top: 3.60459vw; } }
  @media (max-width: 767px) {
    ._1SiDcbV3ipSVLu4eqo3NXV {
      padding-top: 57px; } }
  ._1SiDcbV3ipSVLu4eqo3NXV ._27JTkw_Q1JAnIg88bvplLz {
    margin-top: 0; }

._1n1CFLiEax2v8D7n2YFPDc {
  font-family: "Raleway", sans-serif;
  font-weight: 300;
  letter-spacing: -0.03em;
  color: #e6e8e6;
  font-size: 2.34261vw;
  margin-bottom: 2.19619vw; }
  @media (max-width: 1024px) {
    ._1n1CFLiEax2v8D7n2YFPDc {
      font-size: 1.7561vw;
      margin-bottom: 1.64634vw; } }
  @media (max-width: 1280px) {
    ._1n1CFLiEax2v8D7n2YFPDc {
      font-size: 2.02363vw;
      margin-bottom: 1.89715vw; } }
  @media (max-width: 767px) {
    ._1n1CFLiEax2v8D7n2YFPDc {
      font-size: 32px;
      margin-bottom: 30px; } }

._12y2Fzp3a-m1BYvbVWUqXE {
  position: relative;
  background: none;
  border: none;
  padding: 1.83016vw 0vw 1.31772vw 0vw;
  margin-bottom: 2.85505vw;
  min-height: 16.83748vw; }
  @media (max-width: 1024px) {
    ._12y2Fzp3a-m1BYvbVWUqXE {
      padding: 1.37195vw 0vw 0.9878vw 0vw;
      margin-bottom: 2.14024vw;
      min-height: 12.62195vw; } }
  @media (max-width: 1280px) {
    ._12y2Fzp3a-m1BYvbVWUqXE {
      padding: 1.58096vw 0vw 1.13829vw 0vw;
      margin-bottom: 2.4663vw;
      min-height: 14.54482vw; } }
  @media (max-width: 767px) {
    ._12y2Fzp3a-m1BYvbVWUqXE {
      padding: 25px 0px 18px 0px;
      margin-bottom: 39px;
      min-height: 230px; } }
