._2h-ClGn89feRNpo94uonj9 {
  padding: 0;
  display: flex;
  align-items: center;
  background: none;
  outline: none;
  border: none;
  color: #e6e8e6;
  border-top: 1px solid currentColor;
  border-bottom: 1px solid currentColor;
  height: 0.87848vw;
  width: 2.92826vw; }
  @media (max-width: 1024px) {
    ._2h-ClGn89feRNpo94uonj9 {
      height: 0.65854vw;
      width: 2.19512vw; } }
  @media (max-width: 1280px) {
    ._2h-ClGn89feRNpo94uonj9 {
      height: 0.75886vw;
      width: 2.52953vw; } }
  @media (max-width: 767px) {
    ._2h-ClGn89feRNpo94uonj9 {
      height: 12px;
      width: 40px; } }
  ._2h-ClGn89feRNpo94uonj9:after {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background-color: currentColor; }
  ._2h-ClGn89feRNpo94uonj9:focus {
    color: rgba(230, 232, 230, 0.4); }
  ._2h-ClGn89feRNpo94uonj9:active {
    color: #4dd1a1; }
