._2Geq5DjpD-JNhwdF9AlSLX {
  margin-bottom: 3.95315vw; }
  @media (max-width: 1024px) {
    ._2Geq5DjpD-JNhwdF9AlSLX {
      margin-bottom: 2.96341vw; } }
  @media (max-width: 1280px) {
    ._2Geq5DjpD-JNhwdF9AlSLX {
      margin-bottom: 3.41487vw; } }
  @media (max-width: 767px) {
    ._2Geq5DjpD-JNhwdF9AlSLX {
      margin-bottom: 54px; } }

._3TpZ9vNOJ5r3kXv4k6SlI7 {
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  position: relative;
  background-color: #426b5c;
  background: linear-gradient(to right, #426b5c 0%, #374b43 100%);
  color: #e6e8e6;
  min-height: 11.63982vw;
  max-height: 13.90922vw;
  padding: 1.68375vw 1.53734vw 2.41581vw 1.83016vw; }
  @media (max-width: 1024px) {
    ._3TpZ9vNOJ5r3kXv4k6SlI7 {
      min-height: 8.72561vw;
      max-height: 10.42683vw;
      padding: 1.26219vw 1.15244vw 1.81098vw 1.37195vw; } }
  @media (max-width: 1280px) {
    ._3TpZ9vNOJ5r3kXv4k6SlI7 {
      min-height: 10.0549vw;
      max-height: 12.01529vw;
      padding: 1.45448vw 1.32801vw 2.08687vw 1.58096vw; } }
  @media (max-width: 767px) {
    ._3TpZ9vNOJ5r3kXv4k6SlI7 {
      min-height: 159px;
      max-height: 190px;
      padding: 23px 21px 33px 25px; } }
  ._3TpZ9vNOJ5r3kXv4k6SlI7 ._2zz_RX9Ec26kDRCiNAN9nT {
    flex-grow: 1; }

.USLZHLN2XwgDJD1GGwkxa {
  max-height: none; }

.ehE3LY8fMU2W1wFw4Ti8o {
  display: flex;
  flex-direction: column;
  width: 26.93997vw;
  padding: 0.36603vw 5.85652vw 0vw 0vw; }
  @media (max-width: 1024px) {
    .ehE3LY8fMU2W1wFw4Ti8o {
      width: 20.19512vw;
      padding: 0.27439vw 4.39024vw 0vw 0vw; } }
  @media (max-width: 1280px) {
    .ehE3LY8fMU2W1wFw4Ti8o {
      width: 23.27172vw;
      padding: 0.31619vw 5.05907vw 0vw 0vw; } }
  @media (max-width: 767px) {
    .ehE3LY8fMU2W1wFw4Ti8o {
      width: 368px;
      padding: 5px 80px 0px 0px; } }

._26JGn0tQTllTucVeMVE2fs {
  font-family: "Raleway", sans-serif;
  font-weight: 300;
  letter-spacing: -0.03em;
  color: #e6e8e6;
  font-size: 1.90337vw;
  margin-bottom: 1.0981vw; }
  @media (max-width: 1024px) {
    ._26JGn0tQTllTucVeMVE2fs {
      font-size: 1.42683vw;
      margin-bottom: 0.82317vw; } }
  @media (max-width: 1280px) {
    ._26JGn0tQTllTucVeMVE2fs {
      font-size: 1.6442vw;
      margin-bottom: 0.94858vw; } }
  @media (max-width: 767px) {
    ._26JGn0tQTllTucVeMVE2fs {
      font-size: 26px;
      margin-bottom: 15px; } }

._3pyetFknt1g6F-oE0u8Hb8 {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  letter-spacing: -0.03em;
  color: #e6e8e6;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 1.0981vw; }
  @media (max-width: 1024px) {
    ._3pyetFknt1g6F-oE0u8Hb8 {
      font-size: 0.82317vw; } }
  @media (max-width: 1280px) {
    ._3pyetFknt1g6F-oE0u8Hb8 {
      font-size: 0.94858vw; } }
  @media (max-width: 767px) {
    ._3pyetFknt1g6F-oE0u8Hb8 {
      font-size: 15px; } }

.nBghiT2YuWbGxh0UnkV03 {
  transition: fill 0.1s linear;
  width: 1.1713vw;
  height: 0.58565vw;
  fill: inherit; }
  @media (max-width: 1024px) {
    .nBghiT2YuWbGxh0UnkV03 {
      width: 0.87805vw;
      height: 0.43902vw; } }
  @media (max-width: 1280px) {
    .nBghiT2YuWbGxh0UnkV03 {
      width: 1.01181vw;
      height: 0.50591vw; } }
  @media (max-width: 767px) {
    .nBghiT2YuWbGxh0UnkV03 {
      width: 16px;
      height: 8px; } }

._1sUJWPUayYp-CvCKbNnJXO {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg); }

._2zz_RX9Ec26kDRCiNAN9nT {
  width: 23.64568vw; }
  @media (max-width: 1024px) {
    ._2zz_RX9Ec26kDRCiNAN9nT {
      width: 17.72561vw; } }
  @media (max-width: 1280px) {
    ._2zz_RX9Ec26kDRCiNAN9nT {
      width: 20.42599vw; } }
  @media (max-width: 767px) {
    ._2zz_RX9Ec26kDRCiNAN9nT {
      width: 323px; } }

._1ik1rf8ITnEc4v78OcZnNu {
  padding: 0;
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  bottom: 1.0981vw;
  right: 1.1713vw;
  background: none;
  border: none;
  outline: none;
  fill: #e6e8e6; }
  @media (max-width: 1024px) {
    ._1ik1rf8ITnEc4v78OcZnNu {
      bottom: 0.82317vw;
      right: 0.87805vw; } }
  @media (max-width: 1280px) {
    ._1ik1rf8ITnEc4v78OcZnNu {
      bottom: 0.94858vw;
      right: 1.01181vw; } }
  @media (max-width: 767px) {
    ._1ik1rf8ITnEc4v78OcZnNu {
      bottom: 15px;
      right: 16px; } }
  ._1ik1rf8ITnEc4v78OcZnNu:hover {
    fill: rgba(230, 232, 230, 0.4);
    color: rgba(230, 232, 230, 0.4); }
  ._1ik1rf8ITnEc4v78OcZnNu:active {
    fill: #4dd1a1;
    color: #4dd1a1; }
