._2JvnW-eV5Y2ZNbWYYzFcVH .HKvG71qR6-jyfNe4EjGM-,
._2JvnW-eV5Y2ZNbWYYzFcVH .pTK2rWd7nt3s4bqpdIEt3,
._2JvnW-eV5Y2ZNbWYYzFcVH ._2LEqMnAJN_LmIGSBlTuZ-Y {
  display: inline-block;
  background-color: rgba(143, 143, 143, 0.5);
  border-radius: 20px; }

._2JvnW-eV5Y2ZNbWYYzFcVH .HKvG71qR6-jyfNe4EjGM- {
  width: 7.17423vw;
  height: 1.39092vw;
  margin: 0vw 1.46413vw; }
  @media (max-width: 1024px) {
    ._2JvnW-eV5Y2ZNbWYYzFcVH .HKvG71qR6-jyfNe4EjGM- {
      width: 5.37805vw;
      height: 1.04268vw;
      margin: 0vw 1.09756vw; } }
  @media (max-width: 1280px) {
    ._2JvnW-eV5Y2ZNbWYYzFcVH .HKvG71qR6-jyfNe4EjGM- {
      width: 6.19736vw;
      height: 1.20153vw;
      margin: 0vw 1.26477vw; } }
  @media (max-width: 767px) {
    ._2JvnW-eV5Y2ZNbWYYzFcVH .HKvG71qR6-jyfNe4EjGM- {
      width: 98px;
      height: 19px;
      margin: 0px 20px; } }

._2JvnW-eV5Y2ZNbWYYzFcVH .pTK2rWd7nt3s4bqpdIEt3 {
  width: 18.37482vw;
  height: 2.70864vw;
  margin-left: 1.46413vw;
  margin-right: 0.51245vw; }
  @media (max-width: 1024px) {
    ._2JvnW-eV5Y2ZNbWYYzFcVH .pTK2rWd7nt3s4bqpdIEt3 {
      width: 13.77439vw;
      height: 2.03049vw;
      margin-left: 1.09756vw;
      margin-right: 0.38415vw; } }
  @media (max-width: 1280px) {
    ._2JvnW-eV5Y2ZNbWYYzFcVH .pTK2rWd7nt3s4bqpdIEt3 {
      width: 15.87283vw;
      height: 2.33982vw;
      margin-left: 1.26477vw;
      margin-right: 0.44267vw; } }
  @media (max-width: 767px) {
    ._2JvnW-eV5Y2ZNbWYYzFcVH .pTK2rWd7nt3s4bqpdIEt3 {
      width: 251px;
      height: 37px;
      margin-left: 20px;
      margin-right: 7px; } }

._2JvnW-eV5Y2ZNbWYYzFcVH ._2LEqMnAJN_LmIGSBlTuZ-Y {
  border: 0;
  width: 2.48902vw;
  height: 1.39092vw; }
  @media (max-width: 1024px) {
    ._2JvnW-eV5Y2ZNbWYYzFcVH ._2LEqMnAJN_LmIGSBlTuZ-Y {
      width: 1.86585vw;
      height: 1.04268vw; } }
  @media (max-width: 1280px) {
    ._2JvnW-eV5Y2ZNbWYYzFcVH ._2LEqMnAJN_LmIGSBlTuZ-Y {
      width: 2.1501vw;
      height: 1.20153vw; } }
  @media (max-width: 767px) {
    ._2JvnW-eV5Y2ZNbWYYzFcVH ._2LEqMnAJN_LmIGSBlTuZ-Y {
      width: 34px;
      height: 19px; } }

.DrgusVv_39yvW1xi9AGBO:first-child {
  margin-bottom: 3.66032vw; }
  @media (max-width: 1024px) {
    .DrgusVv_39yvW1xi9AGBO:first-child {
      margin-bottom: 2.7439vw; } }
  @media (max-width: 1280px) {
    .DrgusVv_39yvW1xi9AGBO:first-child {
      margin-bottom: 3.16192vw; } }
  @media (max-width: 767px) {
    .DrgusVv_39yvW1xi9AGBO:first-child {
      margin-bottom: 50px; } }

._2VpJViDAat0sZBkdRqyF41 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #e6e8e6;
  margin-bottom: 0.73206vw; }
  @media (max-width: 1024px) {
    ._2VpJViDAat0sZBkdRqyF41 {
      margin-bottom: 0.54878vw; } }
  @media (max-width: 1280px) {
    ._2VpJViDAat0sZBkdRqyF41 {
      margin-bottom: 0.63238vw; } }
  @media (max-width: 767px) {
    ._2VpJViDAat0sZBkdRqyF41 {
      margin-bottom: 10px; } }
  ._2VpJViDAat0sZBkdRqyF41 > svg {
    fill: currentColor;
    width: 1.90337vw;
    height: 1.97657vw; }
    @media (max-width: 1024px) {
      ._2VpJViDAat0sZBkdRqyF41 > svg {
        width: 1.42683vw;
        height: 1.48171vw; } }
    @media (max-width: 1280px) {
      ._2VpJViDAat0sZBkdRqyF41 > svg {
        width: 1.6442vw;
        height: 1.70744vw; } }
    @media (max-width: 767px) {
      ._2VpJViDAat0sZBkdRqyF41 > svg {
        width: 26px;
        height: 27px; } }

._3CngVyI7yh7tVAKXuDl_vn {
  border: 1px solid #e6e8e6;
  color: #e6e8e6;
  background-color: transparent;
  width: 2.19619vw;
  height: 2.19619vw; }
  @media (max-width: 1024px) {
    ._3CngVyI7yh7tVAKXuDl_vn {
      width: 1.64634vw;
      height: 1.64634vw; } }
  @media (max-width: 1280px) {
    ._3CngVyI7yh7tVAKXuDl_vn {
      width: 1.89715vw;
      height: 1.89715vw; } }
  @media (max-width: 767px) {
    ._3CngVyI7yh7tVAKXuDl_vn {
      width: 30px;
      height: 30px; } }
  ._3CngVyI7yh7tVAKXuDl_vn:not([disabled]):hover, ._3CngVyI7yh7tVAKXuDl_vn:not([disabled]):focus {
    border-color: rgba(230, 232, 230, 0.4);
    color: rgba(230, 232, 230, 0.4); }
  ._3CngVyI7yh7tVAKXuDl_vn:not([disabled]):active {
    background-color: #34edaa;
    border-color: #34edaa;
    color: #282a28; }
  ._3CngVyI7yh7tVAKXuDl_vn[disabled] {
    border-color: rgba(230, 232, 230, 0.15);
    color: rgba(230, 232, 230, 0.15); }

._1E0UKRLPrjqkz-76cJ1Zy0 {
  color: #e6e8e6;
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  border: none;
  outline: none;
  transition: all 0.1s linear;
  padding: 0.36603vw; }
  @media (max-width: 1024px) {
    ._1E0UKRLPrjqkz-76cJ1Zy0 {
      padding: 0.27439vw; } }
  @media (max-width: 1280px) {
    ._1E0UKRLPrjqkz-76cJ1Zy0 {
      padding: 0.31619vw; } }
  @media (max-width: 767px) {
    ._1E0UKRLPrjqkz-76cJ1Zy0 {
      padding: 5px; } }
  ._1E0UKRLPrjqkz-76cJ1Zy0:not([disabled]):hover {
    color: rgba(230, 232, 230, 0.4); }
  ._1E0UKRLPrjqkz-76cJ1Zy0:not([disabled]):active {
    color: #4dd1a1; }
  ._1E0UKRLPrjqkz-76cJ1Zy0[disabled] {
    cursor: default;
    color: rgba(230, 232, 230, 0.15); }
  ._1E0UKRLPrjqkz-76cJ1Zy0 > svg {
    fill: currentColor;
    width: 0.58565vw;
    height: 1.1713vw; }
    @media (max-width: 1024px) {
      ._1E0UKRLPrjqkz-76cJ1Zy0 > svg {
        width: 0.43902vw;
        height: 0.87805vw; } }
    @media (max-width: 1280px) {
      ._1E0UKRLPrjqkz-76cJ1Zy0 > svg {
        width: 0.50591vw;
        height: 1.01181vw; } }
    @media (max-width: 767px) {
      ._1E0UKRLPrjqkz-76cJ1Zy0 > svg {
        width: 8px;
        height: 16px; } }

.HKvG71qR6-jyfNe4EjGM- {
  font-size: 1.83016vw;
  padding: 0vw 1.61054vw 0vw 1.61054vw; }
  @media (max-width: 1024px) {
    .HKvG71qR6-jyfNe4EjGM- {
      font-size: 1.37195vw;
      padding: 0vw 1.20732vw 0vw 1.20732vw; } }
  @media (max-width: 1280px) {
    .HKvG71qR6-jyfNe4EjGM- {
      font-size: 1.58096vw;
      padding: 0vw 1.39124vw 0vw 1.39124vw; } }
  @media (max-width: 767px) {
    .HKvG71qR6-jyfNe4EjGM- {
      font-size: 25px;
      padding: 0px 22px 0px 22px; } }

._3jREKO2D9FPUZVSWCcyg7J {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  font-family: "Chonburi", sans-serif;
  color: #e6e8e6; }

._4AmMN0QD72iTO-I31SGym {
  font-variant: small-caps;
  font-size: 2.41581vw;
  line-height: 2.56223vw; }
  @media (max-width: 1024px) {
    ._4AmMN0QD72iTO-I31SGym {
      font-size: 1.81098vw;
      line-height: 1.92073vw; } }
  @media (max-width: 1280px) {
    ._4AmMN0QD72iTO-I31SGym {
      font-size: 2.08687vw;
      line-height: 2.21334vw; } }
  @media (max-width: 767px) {
    ._4AmMN0QD72iTO-I31SGym {
      font-size: 33px;
      line-height: 35px; } }

.pTK2rWd7nt3s4bqpdIEt3 {
  font-size: 5.85652vw;
  line-height: 5.85652vw;
  height: 5.85652vw;
  padding: 0vw 0.73206vw 0vw 0.51245vw; }
  @media (max-width: 1024px) {
    .pTK2rWd7nt3s4bqpdIEt3 {
      font-size: 4.39024vw;
      line-height: 4.39024vw;
      height: 4.39024vw;
      padding: 0vw 0.54878vw 0vw 0.38415vw; } }
  @media (max-width: 1280px) {
    .pTK2rWd7nt3s4bqpdIEt3 {
      font-size: 5.05907vw;
      line-height: 5.05907vw;
      height: 5.05907vw;
      padding: 0vw 0.63238vw 0vw 0.44267vw; } }
  @media (max-width: 767px) {
    .pTK2rWd7nt3s4bqpdIEt3 {
      font-size: 80px;
      line-height: 80px;
      height: 80px;
      padding: 0px 10px 0px 7px; } }

._2LEqMnAJN_LmIGSBlTuZ-Y {
  border-bottom: 2px solid #fff;
  font-size: 2.92826vw;
  line-height: 2.92826vw; }
  @media (max-width: 1024px) {
    ._2LEqMnAJN_LmIGSBlTuZ-Y {
      font-size: 2.19512vw;
      line-height: 2.19512vw; } }
  @media (max-width: 1280px) {
    ._2LEqMnAJN_LmIGSBlTuZ-Y {
      font-size: 2.52953vw;
      line-height: 2.52953vw; } }
  @media (max-width: 767px) {
    ._2LEqMnAJN_LmIGSBlTuZ-Y {
      font-size: 40px;
      line-height: 40px; } }

._3Esqm8Q4SSwEAUU2zv3nMa {
  align-self: stretch;
  display: flex;
  align-items: center;
  margin-left: 1.68375vw; }
  @media (max-width: 1024px) {
    ._3Esqm8Q4SSwEAUU2zv3nMa {
      margin-left: 1.26219vw; } }
  @media (max-width: 1280px) {
    ._3Esqm8Q4SSwEAUU2zv3nMa {
      margin-left: 1.45448vw; } }
  @media (max-width: 767px) {
    ._3Esqm8Q4SSwEAUU2zv3nMa {
      margin-left: 23px; } }
