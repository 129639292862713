._2hKLMwDAs4WCNCIYFvqY85 {
  margin-bottom: 0.73206vw; }
  @media (max-width: 1024px) {
    ._2hKLMwDAs4WCNCIYFvqY85 {
      margin-bottom: 0.54878vw; } }
  @media (max-width: 1280px) {
    ._2hKLMwDAs4WCNCIYFvqY85 {
      margin-bottom: 0.63238vw; } }
  @media (max-width: 767px) {
    ._2hKLMwDAs4WCNCIYFvqY85 {
      margin-bottom: 10px; } }

._15rD71BdldFNY9ThCCxb1K {
  width: 100%;
  padding: 1.53734vw 1.46413vw 1.90337vw 1.46413vw;
  min-height: 8.56515vw;
  background-color: #383638;
  -webkit-transform: translateX(0);
          transform: translateX(0);
  transition: -webkit-transform 0.1s ease;
  transition: transform 0.1s ease;
  transition: transform 0.1s ease, -webkit-transform 0.1s ease;
  border: none;
  text-align: left;
  color: #fff; }
  @media (max-width: 1024px) {
    ._15rD71BdldFNY9ThCCxb1K {
      padding: 1.15244vw 1.09756vw 1.42683vw 1.09756vw;
      min-height: 6.42073vw; } }
  @media (max-width: 1280px) {
    ._15rD71BdldFNY9ThCCxb1K {
      padding: 1.32801vw 1.26477vw 1.6442vw 1.26477vw;
      min-height: 7.39889vw; } }
  @media (max-width: 767px) {
    ._15rD71BdldFNY9ThCCxb1K {
      padding: 21px 20px 26px 20px;
      min-height: 117px; } }
  ._15rD71BdldFNY9ThCCxb1K:hover {
    -webkit-transform: translateX(5px);
            transform: translateX(5px); }

._3OqpXlYfD8rDT17KLHr1fL {
  background-color: transparent;
  border: 1px solid rgba(56, 242, 191, 0.5); }
  ._3OqpXlYfD8rDT17KLHr1fL:hover {
    -webkit-transform: translateX(0);
            transform: translateX(0); }

._21Q7cmjYICcJhqr3GztmQr {
  margin-bottom: 1.0981vw; }
  @media (max-width: 1024px) {
    ._21Q7cmjYICcJhqr3GztmQr {
      margin-bottom: 0.82317vw; } }
  @media (max-width: 1280px) {
    ._21Q7cmjYICcJhqr3GztmQr {
      margin-bottom: 0.94858vw; } }
  @media (max-width: 767px) {
    ._21Q7cmjYICcJhqr3GztmQr {
      margin-bottom: 15px; } }

.Ei-63IwZm3WbswonAnLu1 {
  margin-bottom: 0.95168vw; }
  @media (max-width: 1024px) {
    .Ei-63IwZm3WbswonAnLu1 {
      margin-bottom: 0.71341vw; } }
  @media (max-width: 1280px) {
    .Ei-63IwZm3WbswonAnLu1 {
      margin-bottom: 0.8221vw; } }
  @media (max-width: 767px) {
    .Ei-63IwZm3WbswonAnLu1 {
      margin-bottom: 13px; } }

._1i0ygsGoEa6kxriKd17MB0 {
  color: #35e89f;
  line-height: 1.33333;
  margin-right: 0.21962vw;
  min-width: 10.2489vw;
  font-size: 1.0981vw;
  display: inline-block;
  float: left; }
  @media (max-width: 1024px) {
    ._1i0ygsGoEa6kxriKd17MB0 {
      margin-right: 0.16463vw;
      min-width: 7.68292vw;
      font-size: 0.82317vw; } }
  @media (max-width: 1280px) {
    ._1i0ygsGoEa6kxriKd17MB0 {
      margin-right: 0.18972vw;
      min-width: 8.85337vw;
      font-size: 0.94858vw; } }
  @media (max-width: 767px) {
    ._1i0ygsGoEa6kxriKd17MB0 {
      margin-right: 3px;
      min-width: 140px;
      font-size: 15px; } }

._2FSN_reFaoeNrgMsidaNxI {
  border: 1px solid rgba(56, 242, 191, 0.5);
  padding: 20px; }
  ._2FSN_reFaoeNrgMsidaNxI ._2ANHGIenT62FpxucSl5yHj {
    font-family: "Raleway", sans-serif;
    font-weight: 300;
    font-size: 1.90337vw;
    line-height: 1.33333;
    color: #e6e8e6; }
    @media (max-width: 1024px) {
      ._2FSN_reFaoeNrgMsidaNxI ._2ANHGIenT62FpxucSl5yHj {
        font-size: 1.42683vw; } }
    @media (max-width: 1280px) {
      ._2FSN_reFaoeNrgMsidaNxI ._2ANHGIenT62FpxucSl5yHj {
        font-size: 1.6442vw; } }
    @media (max-width: 767px) {
      ._2FSN_reFaoeNrgMsidaNxI ._2ANHGIenT62FpxucSl5yHj {
        font-size: 26px; } }

.fMV5RM4UlymPgvCEJNwNA {
  display: flex;
  align-items: baseline; }

.KnqZeltNFOIa5mmrUnvBX {
  line-height: 1.3;
  font-size: 1.46413vw;
  display: inline-block;
  font-style: light; }
  @media (max-width: 1024px) {
    .KnqZeltNFOIa5mmrUnvBX {
      font-size: 1.09756vw; } }
  @media (max-width: 1280px) {
    .KnqZeltNFOIa5mmrUnvBX {
      font-size: 1.26477vw; } }
  @media (max-width: 767px) {
    .KnqZeltNFOIa5mmrUnvBX {
      font-size: 20px; } }

._2ANHGIenT62FpxucSl5yHj {
  line-height: 1;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  font-size: 1.90337vw; }
  @media (max-width: 1024px) {
    ._2ANHGIenT62FpxucSl5yHj {
      font-size: 1.42683vw; } }
  @media (max-width: 1280px) {
    ._2ANHGIenT62FpxucSl5yHj {
      font-size: 1.6442vw; } }
  @media (max-width: 767px) {
    ._2ANHGIenT62FpxucSl5yHj {
      font-size: 26px; } }

._2ri5m0mO9GRr21DKnkcvne {
  font-family: "Raleway", sans-serif;
  font-weight: 300;
  font-size: 1.90337vw;
  line-height: 2.48902vw; }
  @media (max-width: 1024px) {
    ._2ri5m0mO9GRr21DKnkcvne {
      font-size: 1.42683vw; } }
  @media (max-width: 1280px) {
    ._2ri5m0mO9GRr21DKnkcvne {
      font-size: 1.6442vw; } }
  @media (max-width: 767px) {
    ._2ri5m0mO9GRr21DKnkcvne {
      font-size: 26px; } }
  @media (max-width: 1024px) {
    ._2ri5m0mO9GRr21DKnkcvne {
      line-height: 1.86585vw; } }
  @media (max-width: 1280px) {
    ._2ri5m0mO9GRr21DKnkcvne {
      line-height: 2.1501vw; } }
  @media (max-width: 767px) {
    ._2ri5m0mO9GRr21DKnkcvne {
      line-height: 34px; } }
  ._2ri5m0mO9GRr21DKnkcvne:hover {
    cursor: pointer;
    color: #35e89f;
    border-bottom: 1px solid #35e89f; }
