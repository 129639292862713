._2TfZVC85vuoF9HYJrq8gzg {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden; }

._230aIUydpID432PvjnRFN {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 2.19619vw; }
  @media (max-width: 1024px) {
    ._230aIUydpID432PvjnRFN {
      padding-bottom: 1.64634vw; } }
  @media (max-width: 1280px) {
    ._230aIUydpID432PvjnRFN {
      padding-bottom: 1.89715vw; } }
  @media (max-width: 767px) {
    ._230aIUydpID432PvjnRFN {
      padding-bottom: 30px; } }

._4kzs4QWhVK3VO_aB_1KaM {
  font-family: "Raleway", sans-serif;
  font-weight: 500;
  color: #5d8c7b;
  text-align: center;
  font-size: 2.04978vw;
  line-height: 3.3675vw; }
  @media (max-width: 1024px) {
    ._4kzs4QWhVK3VO_aB_1KaM {
      font-size: 1.53658vw;
      line-height: 2.52439vw; } }
  @media (max-width: 1280px) {
    ._4kzs4QWhVK3VO_aB_1KaM {
      font-size: 1.77067vw;
      line-height: 2.90896vw; } }
  @media (max-width: 767px) {
    ._4kzs4QWhVK3VO_aB_1KaM {
      font-size: 28px;
      line-height: 46px; } }

._2FIDyqUiW5o5NHc2Si2R6V {
  width: 3.80673vw;
  height: 4.39239vw;
  margin-bottom: 2.19619vw;
  background-size: 3.80673vw 4.39239vw; }
  @media (max-width: 1024px) {
    ._2FIDyqUiW5o5NHc2Si2R6V {
      width: 2.85366vw;
      height: 3.29268vw;
      margin-bottom: 1.64634vw;
      background-size: 2.85366vw 3.29268vw; } }
  @media (max-width: 1280px) {
    ._2FIDyqUiW5o5NHc2Si2R6V {
      width: 3.28839vw;
      height: 3.7943vw;
      margin-bottom: 1.89715vw;
      background-size: 3.28839vw 3.7943vw; } }
  @media (max-width: 767px) {
    ._2FIDyqUiW5o5NHc2Si2R6V {
      width: 52px;
      height: 60px;
      margin-bottom: 30px;
      background-size: 52px 60px; } }

._1rKYcEygDUF_sFjsFAjxUz {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden; }
  ._2lmjhAUSFZVjn8jCcl9sfk {
    font-family: "Raleway", sans-serif;
    font-weight: 400;
    letter-spacing: -0.03em;
    color: #e6e8e6;
    font-size: 2.41581vw;
    margin-bottom: 2.19619vw; }
    @media (max-width: 1024px) {
      ._2lmjhAUSFZVjn8jCcl9sfk {
        font-size: 1.81098vw;
        margin-bottom: 1.64634vw; } }
    @media (max-width: 1280px) {
      ._2lmjhAUSFZVjn8jCcl9sfk {
        font-size: 2.08687vw;
        margin-bottom: 1.89715vw; } }
    @media (max-width: 767px) {
      ._2lmjhAUSFZVjn8jCcl9sfk {
        font-size: 33px;
        margin-bottom: 30px; } }
