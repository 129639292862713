._1HVPiKLf8ipHtOlkzxRiMD {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center; }

._jkVnaTsNsaOW6hXzoPCK {
  position: relative;
  min-width: 550px;
  background-color: #689b88;
  color: #e6e8e6;
  font-family: "Roboto", sans-serif; }
  ._jkVnaTsNsaOW6hXzoPCK .Select-menu {
    background-color: #689b88; }

._1IBPTBmNLTKrZr4a4aT7uN {
  background-color: #5d8c7b;
  padding: 1.53734vw 2.19619vw 1.39092vw; }
  @media (max-width: 1024px) {
    ._1IBPTBmNLTKrZr4a4aT7uN {
      padding: 1.15244vw 1.64634vw 1.04268vw; } }
  @media (max-width: 1280px) {
    ._1IBPTBmNLTKrZr4a4aT7uN {
      padding: 1.32801vw 1.89715vw 1.20153vw; } }
  @media (max-width: 767px) {
    ._1IBPTBmNLTKrZr4a4aT7uN {
      padding: 21px 30px 19px; } }

.KpNj1Z5olxtxvDvICa2Dc {
  font-weight: 300;
  padding: 1.83016vw 2.19619vw 2.34261vw;
  font-size: 1.1713vw; }
  @media (max-width: 1024px) {
    .KpNj1Z5olxtxvDvICa2Dc {
      padding: 1.37195vw 1.64634vw 1.7561vw;
      font-size: 0.87805vw; } }
  @media (max-width: 1280px) {
    .KpNj1Z5olxtxvDvICa2Dc {
      padding: 1.58096vw 1.89715vw 2.02363vw;
      font-size: 1.01181vw; } }
  @media (max-width: 767px) {
    .KpNj1Z5olxtxvDvICa2Dc {
      padding: 25px 30px 32px;
      font-size: 16px; } }
  .KpNj1Z5olxtxvDvICa2Dc:last-child {
    margin-bottom: 0; }

.LJK4TaBXESbM3SzSIoGK4 {
  margin: 0;
  text-align: left;
  font-family: "Raleway", sans-serif;
  font-weight: 600;
  font-size: 1.90337vw; }
  @media (max-width: 1024px) {
    .LJK4TaBXESbM3SzSIoGK4 {
      font-size: 1.42683vw; } }
  @media (max-width: 1280px) {
    .LJK4TaBXESbM3SzSIoGK4 {
      font-size: 1.6442vw; } }
  @media (max-width: 767px) {
    .LJK4TaBXESbM3SzSIoGK4 {
      font-size: 26px; } }

._25Hhbu_KpYJ9WDhQuKY50e {
  padding: 2.41581vw 2.19619vw 1.53734vw; }
  @media (max-width: 1024px) {
    ._25Hhbu_KpYJ9WDhQuKY50e {
      padding: 1.81098vw 1.64634vw 1.15244vw; } }
  @media (max-width: 1280px) {
    ._25Hhbu_KpYJ9WDhQuKY50e {
      padding: 2.08687vw 1.89715vw 1.32801vw; } }
  @media (max-width: 767px) {
    ._25Hhbu_KpYJ9WDhQuKY50e {
      padding: 33px 30px 21px; } }
  ._25Hhbu_KpYJ9WDhQuKY50e > button {
    margin-right: 1.46413vw; }
    @media (max-width: 1024px) {
      ._25Hhbu_KpYJ9WDhQuKY50e > button {
        margin-right: 1.09756vw; } }
    @media (max-width: 1280px) {
      ._25Hhbu_KpYJ9WDhQuKY50e > button {
        margin-right: 1.26477vw; } }
    @media (max-width: 767px) {
      ._25Hhbu_KpYJ9WDhQuKY50e > button {
        margin-right: 20px; } }
    ._25Hhbu_KpYJ9WDhQuKY50e > button:last-child {
      margin-right: 0; }

._2b5L_NATjIK52B-tF12d28 {
  position: absolute;
  top: 1.83016vw;
  right: 1.31772vw;
  width: 1.53734vw;
  height: 1.53734vw; }
  @media (max-width: 1024px) {
    ._2b5L_NATjIK52B-tF12d28 {
      top: 1.37195vw;
      right: 0.9878vw;
      width: 1.15244vw;
      height: 1.15244vw; } }
  @media (max-width: 1280px) {
    ._2b5L_NATjIK52B-tF12d28 {
      top: 1.58096vw;
      right: 1.13829vw;
      width: 1.32801vw;
      height: 1.32801vw; } }
  @media (max-width: 767px) {
    ._2b5L_NATjIK52B-tF12d28 {
      top: 25px;
      right: 18px;
      width: 21px;
      height: 21px; } }
  ._2b5L_NATjIK52B-tF12d28 > svg {
    width: 1.53734vw;
    height: 1.53734vw; }
    @media (max-width: 1024px) {
      ._2b5L_NATjIK52B-tF12d28 > svg {
        width: 1.15244vw;
        height: 1.15244vw; } }
    @media (max-width: 1280px) {
      ._2b5L_NATjIK52B-tF12d28 > svg {
        width: 1.32801vw;
        height: 1.32801vw; } }
    @media (max-width: 767px) {
      ._2b5L_NATjIK52B-tF12d28 > svg {
        width: 21px;
        height: 21px; } }
