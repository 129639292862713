.U_BLSjYgVIOHC50d1P_KO {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: #2c2d2c;
  z-index: 10;
  transition: right 0.3s ease;
  padding: 1.24451vw 1.46413vw 0vw 4.09956vw; }
  @media (max-width: 1024px) {
    .U_BLSjYgVIOHC50d1P_KO {
      padding: 0.93293vw 1.09756vw 0vw 3.07317vw; } }
  @media (max-width: 1280px) {
    .U_BLSjYgVIOHC50d1P_KO {
      padding: 1.07505vw 1.26477vw 0vw 3.54135vw; } }
  @media (max-width: 767px) {
    .U_BLSjYgVIOHC50d1P_KO {
      padding: 17px 20px 0px 56px; } }

._1TI7iwCk3SFPcDXXk9w-yq {
  right: -100vw; }

.UWIKD4kPdN8-88wCkoYv0 {
  align-self: flex-end;
  margin-bottom: 1.83016vw; }
  @media (max-width: 1024px) {
    .UWIKD4kPdN8-88wCkoYv0 {
      margin-bottom: 1.37195vw; } }
  @media (max-width: 1280px) {
    .UWIKD4kPdN8-88wCkoYv0 {
      margin-bottom: 1.58096vw; } }
  @media (max-width: 767px) {
    .UWIKD4kPdN8-88wCkoYv0 {
      margin-bottom: 25px; } }

._3SfXzsacsG8jDRaQBgOTPL {
  list-style: none;
  font-family: "Raleway", sans-serif;
  font-weight: 500;
  padding-right: 3.29429vw; }
  @media (max-width: 1024px) {
    ._3SfXzsacsG8jDRaQBgOTPL {
      padding-right: 2.46951vw; } }
  @media (max-width: 1280px) {
    ._3SfXzsacsG8jDRaQBgOTPL {
      padding-right: 2.84573vw; } }
  @media (max-width: 767px) {
    ._3SfXzsacsG8jDRaQBgOTPL {
      padding-right: 45px; } }
  ._3SfXzsacsG8jDRaQBgOTPL > li {
    color: #e6e8e6;
    margin-bottom: 3.07467vw;
    font-size: 1.46413vw; }
    @media (max-width: 1024px) {
      ._3SfXzsacsG8jDRaQBgOTPL > li {
        margin-bottom: 2.30488vw;
        font-size: 1.09756vw; } }
    @media (max-width: 1280px) {
      ._3SfXzsacsG8jDRaQBgOTPL > li {
        margin-bottom: 2.65601vw;
        font-size: 1.26477vw; } }
    @media (max-width: 767px) {
      ._3SfXzsacsG8jDRaQBgOTPL > li {
        margin-bottom: 42px;
        font-size: 20px; } }
    ._3SfXzsacsG8jDRaQBgOTPL > li > button {
      position: relative;
      padding: 0;
      outline: 0;
      border: none;
      background-color: transparent;
      text-align: left; }
      ._3SfXzsacsG8jDRaQBgOTPL > li > button:hover:after {
        content: '';
        display: block;
        width: 100%;
        height: 2px;
        position: absolute;
        left: 0;
        right: 0;
        bottom: -2px;
        background: linear-gradient(95.09deg, #4f695f 1.56%, #4dd1a1 98.75%), #4f695f; }
