._100O1hf1ZcZMqxxyIRXjHJ {
  font-family: "Raleway", sans-serif;
  font-weight: 300;
  color: #ffffff;
  line-height: 1;
  font-size: 2.34261vw; }
  @media (max-width: 1024px) {
    ._100O1hf1ZcZMqxxyIRXjHJ {
      font-size: 1.7561vw; } }
  @media (max-width: 1280px) {
    ._100O1hf1ZcZMqxxyIRXjHJ {
      font-size: 2.02363vw; } }
  @media (max-width: 767px) {
    ._100O1hf1ZcZMqxxyIRXjHJ {
      font-size: 32px; } }
  ._100O1hf1ZcZMqxxyIRXjHJ > svg {
    width: 0.9em;
    height: 0.9em;
    vertical-align: middle;
    fill: #4dd1a1; }

.rvjOdNj3PoIyYIQRh2dQ8 {
  color: rgba(230, 232, 230, 0.4); }
  .rvjOdNj3PoIyYIQRh2dQ8 > svg {
    fill: currentColor; }

._1aQMTxkQGewqx659mFMVw8 {
  display: inline-block;
  vertical-align: middle;
  margin-right: 1.39092vw; }
  @media (max-width: 1024px) {
    ._1aQMTxkQGewqx659mFMVw8 {
      margin-right: 1.04268vw; } }
  @media (max-width: 1280px) {
    ._1aQMTxkQGewqx659mFMVw8 {
      margin-right: 1.20153vw; } }
  @media (max-width: 767px) {
    ._1aQMTxkQGewqx659mFMVw8 {
      margin-right: 19px; } }
