._1z5mLxyu7RKlWcToQoUFRq {
  display: inline-block;
  color: #e6e8e6;
  background-color: #5d8c7b;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  padding: 0; }
  ._1z5mLxyu7RKlWcToQoUFRq > svg {
    fill: currentColor;
    width: 1.0981vw;
    height: 1.0981vw; }
    @media (max-width: 1024px) {
      ._1z5mLxyu7RKlWcToQoUFRq > svg {
        width: 0.82317vw;
        height: 0.82317vw; } }
    @media (max-width: 1280px) {
      ._1z5mLxyu7RKlWcToQoUFRq > svg {
        width: 0.94858vw;
        height: 0.94858vw; } }
    @media (max-width: 767px) {
      ._1z5mLxyu7RKlWcToQoUFRq > svg {
        width: 15px;
        height: 15px; } }
  ._1z5mLxyu7RKlWcToQoUFRq:hover {
    color: #e6e8e6; }
  ._1z5mLxyu7RKlWcToQoUFRq[disabled] {
    color: rgba(230, 232, 230, 0.3);
    pointer-events: none; }
  ._1z5mLxyu7RKlWcToQoUFRq:focus {
    color: #e6e8e6;
    background-color: #4f695f !important;
    outline: none; }
  ._1z5mLxyu7RKlWcToQoUFRq:active {
    color: #282a28;
    background-color: #34edaa !important;
    border: none; }

._1NYCnvSVHTrSTlbRi5CUAv {
  width: 1.90337vw;
  height: 1.90337vw; }
  @media (max-width: 1024px) {
    ._1NYCnvSVHTrSTlbRi5CUAv {
      width: 1.42683vw;
      height: 1.42683vw; } }
  @media (max-width: 1280px) {
    ._1NYCnvSVHTrSTlbRi5CUAv {
      width: 1.6442vw;
      height: 1.6442vw; } }
  @media (max-width: 767px) {
    ._1NYCnvSVHTrSTlbRi5CUAv {
      width: 26px;
      height: 26px; } }

._1yAJe-g87PtZUuXGfgdkP_ {
  width: 3.51391vw;
  height: 3.51391vw; }
  @media (max-width: 1024px) {
    ._1yAJe-g87PtZUuXGfgdkP_ {
      width: 2.63415vw;
      height: 2.63415vw; } }
  @media (max-width: 1280px) {
    ._1yAJe-g87PtZUuXGfgdkP_ {
      width: 3.03544vw;
      height: 3.03544vw; } }
  @media (max-width: 767px) {
    ._1yAJe-g87PtZUuXGfgdkP_ {
      width: 48px;
      height: 48px; } }

._28xvBitbdP585CljKmdMYk {
  width: 4.39239vw;
  height: 4.39239vw; }
  @media (max-width: 1024px) {
    ._28xvBitbdP585CljKmdMYk {
      width: 3.29268vw;
      height: 3.29268vw; } }
  @media (max-width: 1280px) {
    ._28xvBitbdP585CljKmdMYk {
      width: 3.7943vw;
      height: 3.7943vw; } }
  @media (max-width: 767px) {
    ._28xvBitbdP585CljKmdMYk {
      width: 60px;
      height: 60px; } }

@-webkit-keyframes _3-73-IsXvp0Muta_zPZd-p {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes _3-73-IsXvp0Muta_zPZd-p {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

._1u4jZstYZMCtD0ZZRl4_du {
  -webkit-animation: _3-73-IsXvp0Muta_zPZd-p 0.6s linear infinite;
          animation: _3-73-IsXvp0Muta_zPZd-p 0.6s linear infinite; }
