._1bSgUs1Il5910utMlap_vl {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 19.03367vw; }
  @media (max-width: 1024px) {
    ._1bSgUs1Il5910utMlap_vl {
      width: 14.26829vw; } }
  @media (max-width: 1280px) {
    ._1bSgUs1Il5910utMlap_vl {
      width: 16.44197vw; } }
  @media (max-width: 767px) {
    ._1bSgUs1Il5910utMlap_vl {
      width: 260px; } }

._2Pt24GKC4XXdVsYpZlucdu {
  display: block;
  width: 27.81845vw; }
  @media (max-width: 1024px) {
    ._2Pt24GKC4XXdVsYpZlucdu {
      width: 20.85365vw; } }
  @media (max-width: 1280px) {
    ._2Pt24GKC4XXdVsYpZlucdu {
      width: 24.03058vw; } }
  @media (max-width: 767px) {
    ._2Pt24GKC4XXdVsYpZlucdu {
      width: 380px; } }

.fVTL-gvGxvpe-2du86D8Q {
  font-family: "Raleway", sans-serif;
  font-weight: 600;
  color: #e6e8e6;
  font-size: 1.0981vw;
  padding-top: 0.73206vw;
  margin-bottom: 0.58565vw; }
  @media (max-width: 1024px) {
    .fVTL-gvGxvpe-2du86D8Q {
      font-size: 0.82317vw;
      padding-top: 0.54878vw;
      margin-bottom: 0.43902vw; } }
  @media (max-width: 1280px) {
    .fVTL-gvGxvpe-2du86D8Q {
      font-size: 0.94858vw;
      padding-top: 0.63238vw;
      margin-bottom: 0.50591vw; } }
  @media (max-width: 767px) {
    .fVTL-gvGxvpe-2du86D8Q {
      font-size: 15px;
      padding-top: 10px;
      margin-bottom: 8px; } }

._16rMID0OlyTxp8yRueB-SW {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 0.73206vw; }
  @media (max-width: 1024px) {
    ._16rMID0OlyTxp8yRueB-SW {
      margin-bottom: 0.54878vw; } }
  @media (max-width: 1280px) {
    ._16rMID0OlyTxp8yRueB-SW {
      margin-bottom: 0.63238vw; } }
  @media (max-width: 767px) {
    ._16rMID0OlyTxp8yRueB-SW {
      margin-bottom: 10px; } }
  ._16rMID0OlyTxp8yRueB-SW:last-child {
    margin-bottom: 0; }

.wp6GTYtyxKMm0YwCCVCRR {
  list-style: none;
  margin-bottom: 0.21962vw; }
  @media (max-width: 1024px) {
    .wp6GTYtyxKMm0YwCCVCRR {
      margin-bottom: 0.16463vw; } }
  @media (max-width: 1280px) {
    .wp6GTYtyxKMm0YwCCVCRR {
      margin-bottom: 0.18972vw; } }
  @media (max-width: 767px) {
    .wp6GTYtyxKMm0YwCCVCRR {
      margin-bottom: 3px; } }
  .wp6GTYtyxKMm0YwCCVCRR:last-child {
    margin-bottom: 0; }
    .wp6GTYtyxKMm0YwCCVCRR:last-child > li {
      margin-bottom: 0; }

._2tbYiH6QszYMAp01KzQZiN {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  letter-spacing: -0.03em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #e6e8e6;
  font-size: 1.0981vw;
  line-height: 1.75695vw;
  max-width: 12.4451vw; }
  @media (max-width: 1024px) {
    ._2tbYiH6QszYMAp01KzQZiN {
      font-size: 0.82317vw;
      line-height: 1.31707vw;
      max-width: 9.32927vw; } }
  @media (max-width: 1280px) {
    ._2tbYiH6QszYMAp01KzQZiN {
      font-size: 0.94858vw;
      line-height: 1.51772vw;
      max-width: 10.75052vw; } }
  @media (max-width: 767px) {
    ._2tbYiH6QszYMAp01KzQZiN {
      font-size: 15px;
      line-height: 24px;
      max-width: 170px; } }
  ._2tbYiH6QszYMAp01KzQZiN:hover {
    color: #35E89F;
    text-decoration: underline; }

._2vx8DHKViLDxLL0Y-4OvVV {
  font-family: "Roboto", sans-serif;
  font-weight: 800;
  color: #5d8c7b;
  padding-left: 0.73206vw;
  font-size: 1.46413vw; }
  @media (max-width: 1024px) {
    ._2vx8DHKViLDxLL0Y-4OvVV {
      padding-left: 0.54878vw;
      font-size: 1.09756vw; } }
  @media (max-width: 1280px) {
    ._2vx8DHKViLDxLL0Y-4OvVV {
      padding-left: 0.63238vw;
      font-size: 1.26477vw; } }
  @media (max-width: 767px) {
    ._2vx8DHKViLDxLL0Y-4OvVV {
      padding-left: 10px;
      font-size: 20px; } }
