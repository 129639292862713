.-i_iuxdOwSgYaEZI6cpcd {
  display: flex;
  flex-direction: column; }

.mq-7oThjVt2eSNvZ6-7gJ {
  color: #e6e8e6;
  font-family: "Raleway", sans-serif;
  font-weight: 300;
  font-size: 2.92826vw; }
  @media (max-width: 1024px) {
    .mq-7oThjVt2eSNvZ6-7gJ {
      font-size: 2.19512vw; } }
  @media (max-width: 1280px) {
    .mq-7oThjVt2eSNvZ6-7gJ {
      font-size: 2.52953vw; } }
  @media (max-width: 767px) {
    .mq-7oThjVt2eSNvZ6-7gJ {
      font-size: 40px; } }

._1iRNmS7Qqj_S3LXNb6U5rP {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3.66032vw; }
  @media (max-width: 1024px) {
    ._1iRNmS7Qqj_S3LXNb6U5rP {
      margin-bottom: 2.7439vw; } }
  @media (max-width: 1280px) {
    ._1iRNmS7Qqj_S3LXNb6U5rP {
      margin-bottom: 3.16192vw; } }
  @media (max-width: 767px) {
    ._1iRNmS7Qqj_S3LXNb6U5rP {
      margin-bottom: 50px; } }

._1CIzNCH74UBKgNrQEpHyg7 {
  padding: 0;
  margin: 0;
  list-style: none; }
