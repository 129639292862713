._2PFBfuFEMhD7-zMpht8Eow {
  height: 3.4407vw;
  margin-bottom: 0.36603vw;
  font-size: 1.31772vw; }
  @media (max-width: 1024px) {
    ._2PFBfuFEMhD7-zMpht8Eow {
      height: 2.57927vw;
      margin-bottom: 0.27439vw;
      font-size: 0.9878vw; } }
  @media (max-width: 1280px) {
    ._2PFBfuFEMhD7-zMpht8Eow {
      height: 2.9722vw;
      margin-bottom: 0.31619vw;
      font-size: 1.13829vw; } }
  @media (max-width: 767px) {
    ._2PFBfuFEMhD7-zMpht8Eow {
      height: 47px;
      margin-bottom: 5px;
      font-size: 18px; } }
  ._2PFBfuFEMhD7-zMpht8Eow:nth-child(5n + 1) > ._3fa41AD7EACokIHZ9ja4z {
    background-color: #5d8c7b; }
  ._2PFBfuFEMhD7-zMpht8Eow:nth-child(5n + 2) > ._3fa41AD7EACokIHZ9ja4z,
  ._2PFBfuFEMhD7-zMpht8Eow:nth-child(5n + 2) > ._17UhgA9M-333Aln-pKS2q4 {
    background-color: rgba(93, 140, 123, 0.75); }
  ._2PFBfuFEMhD7-zMpht8Eow:nth-child(5n + 3) > ._3fa41AD7EACokIHZ9ja4z,
  ._2PFBfuFEMhD7-zMpht8Eow:nth-child(5n + 3) > ._17UhgA9M-333Aln-pKS2q4 {
    background-color: rgba(93, 140, 123, 0.55); }
  ._2PFBfuFEMhD7-zMpht8Eow:nth-child(5n + 4) > ._3fa41AD7EACokIHZ9ja4z,
  ._2PFBfuFEMhD7-zMpht8Eow:nth-child(5n + 4) > ._17UhgA9M-333Aln-pKS2q4 {
    background-color: rgba(93, 140, 123, 0.45); }
  ._2PFBfuFEMhD7-zMpht8Eow:nth-child(5n + 5) > ._3fa41AD7EACokIHZ9ja4z,
  ._2PFBfuFEMhD7-zMpht8Eow:nth-child(5n + 5) > ._17UhgA9M-333Aln-pKS2q4 {
    background-color: rgba(93, 140, 123, 0.3); }

._3fa41AD7EACokIHZ9ja4z {
  height: 100%;
  width: 3.66032vw;
  margin-right: 0.36603vw; }
  @media (max-width: 1024px) {
    ._3fa41AD7EACokIHZ9ja4z {
      width: 2.7439vw;
      margin-right: 0.27439vw; } }
  @media (max-width: 1280px) {
    ._3fa41AD7EACokIHZ9ja4z {
      width: 3.16192vw;
      margin-right: 0.31619vw; } }
  @media (max-width: 767px) {
    ._3fa41AD7EACokIHZ9ja4z {
      width: 50px;
      margin-right: 5px; } }
