._3qLLUbv2AslmN8Bs4NWHm6 {
  position: relative;
  margin-bottom: 3.58712vw; }
  @media (max-width: 1024px) {
    ._3qLLUbv2AslmN8Bs4NWHm6 {
      margin-bottom: 2.68902vw; } }
  @media (max-width: 1280px) {
    ._3qLLUbv2AslmN8Bs4NWHm6 {
      margin-bottom: 3.09868vw; } }
  @media (max-width: 767px) {
    ._3qLLUbv2AslmN8Bs4NWHm6 {
      margin-bottom: 49px; } }

._3fMcXOBevqsbwQEPDKa6Fe {
  width: 100%;
  font-weight: 300;
  font-family: "Roboto", sans-serif;
  color: #e6e8e6;
  border: none;
  outline: none;
  background-color: rgba(53, 232, 159, 0.15);
  height: 3.4407vw;
  padding: 0vw 3.07467vw 0vw 1.46413vw;
  font-size: 1.83016vw; }
  @media (max-width: 1024px) {
    ._3fMcXOBevqsbwQEPDKa6Fe {
      height: 2.57927vw;
      padding: 0vw 2.30488vw 0vw 1.09756vw;
      font-size: 1.37195vw; } }
  @media (max-width: 1280px) {
    ._3fMcXOBevqsbwQEPDKa6Fe {
      height: 2.9722vw;
      padding: 0vw 2.65601vw 0vw 1.26477vw;
      font-size: 1.58096vw; } }
  @media (max-width: 767px) {
    ._3fMcXOBevqsbwQEPDKa6Fe {
      height: 47px;
      padding: 0px 42px 0px 20px;
      font-size: 25px; } }
  ._17acGuY64xIGoXfvrckAPV {
    color: rgba(230, 232, 230, 0.7); }

._3Pk4n_eJ_U6W8ilIE9DAv1 {
  position: absolute;
  top: 50%;
  background-image: url("/images/magnifier.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  opacity: 0.7;
  width: 1.75695vw;
  height: 2.04978vw;
  margin-top: -1.02489vw;
  right: 1.75695vw; }
  @media (max-width: 1024px) {
    ._3Pk4n_eJ_U6W8ilIE9DAv1 {
      width: 1.31707vw;
      height: 1.53658vw;
      margin-top: -0.76829vw;
      right: 1.31707vw; } }
  @media (max-width: 1280px) {
    ._3Pk4n_eJ_U6W8ilIE9DAv1 {
      width: 1.51772vw;
      height: 1.77067vw;
      margin-top: -0.88534vw;
      right: 1.51772vw; } }
  @media (max-width: 767px) {
    ._3Pk4n_eJ_U6W8ilIE9DAv1 {
      width: 24px;
      height: 28px;
      margin-top: -14px;
      right: 24px; } }
