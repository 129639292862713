._19DkY92B4JNkhBufwFdQ2N {
  display: flex;
  position: relative;
  padding-bottom: 2.19619vw;
  margin-bottom: 2.19619vw; }
  @media (max-width: 1024px) {
    ._19DkY92B4JNkhBufwFdQ2N {
      padding-bottom: 1.64634vw;
      margin-bottom: 1.64634vw; } }
  @media (max-width: 1280px) {
    ._19DkY92B4JNkhBufwFdQ2N {
      padding-bottom: 1.89715vw;
      margin-bottom: 1.89715vw; } }
  @media (max-width: 767px) {
    ._19DkY92B4JNkhBufwFdQ2N {
      padding-bottom: 30px;
      margin-bottom: 30px; } }
  ._19DkY92B4JNkhBufwFdQ2N input[type='checkbox'] {
    visibility: hidden; }
  ._19DkY92B4JNkhBufwFdQ2N:after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    height: 1px;
    background-color: #5d8c7b; }
  ._19DkY92B4JNkhBufwFdQ2N:last-child {
    padding-bottom: 0; }
    ._19DkY92B4JNkhBufwFdQ2N:last-child:after {
      display: none; }

.vqaNweiNvOt2RzYLiSIlw {
  display: flex;
  width: 38.06735vw;
  margin-bottom: 1.0981vw; }
  @media (max-width: 1024px) {
    .vqaNweiNvOt2RzYLiSIlw {
      width: 28.53658vw;
      margin-bottom: 0.82317vw; } }
  @media (max-width: 1280px) {
    .vqaNweiNvOt2RzYLiSIlw {
      width: 32.88395vw;
      margin-bottom: 0.94858vw; } }
  @media (max-width: 767px) {
    .vqaNweiNvOt2RzYLiSIlw {
      width: 520px;
      margin-bottom: 15px; } }
  .vqaNweiNvOt2RzYLiSIlw span {
    font-size: 1.0981vw;
    flex: 1; }
    @media (max-width: 1024px) {
      .vqaNweiNvOt2RzYLiSIlw span {
        font-size: 0.82317vw; } }
    @media (max-width: 1280px) {
      .vqaNweiNvOt2RzYLiSIlw span {
        font-size: 0.94858vw; } }
    @media (max-width: 767px) {
      .vqaNweiNvOt2RzYLiSIlw span {
        font-size: 15px; } }

._27zxFY7mFvMjsVUv4WOvG1,
._3se2J35hFnz-Atvu6x3SSX {
  width: 50%;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  line-height: 1.33333;
  font-size: 1.0981vw;
  color: #e6e8e6; }
  @media (max-width: 1024px) {
    ._27zxFY7mFvMjsVUv4WOvG1,
    ._3se2J35hFnz-Atvu6x3SSX {
      font-size: 0.82317vw; } }
  @media (max-width: 1280px) {
    ._27zxFY7mFvMjsVUv4WOvG1,
    ._3se2J35hFnz-Atvu6x3SSX {
      font-size: 0.94858vw; } }
  @media (max-width: 767px) {
    ._27zxFY7mFvMjsVUv4WOvG1,
    ._3se2J35hFnz-Atvu6x3SSX {
      font-size: 15px; } }

._27zxFY7mFvMjsVUv4WOvG1 {
  font-weight: 300; }

._3ZzIaXkWxB7xu6Vgplswsv {
  font-weight: 600; }

._3se2J35hFnz-Atvu6x3SSX {
  font-weight: 600; }

.QbKUoWaJuuiA2dA1NWRvD {
  background-color: rgba(187, 62, 62, 0.4);
  position: relative;
  border-radius: 40px;
  width: 4.09956vw;
  height: 1.68375vw;
  margin: 1.46413vw 1.90337vw; }
  @media (max-width: 1024px) {
    .QbKUoWaJuuiA2dA1NWRvD {
      width: 3.07317vw;
      height: 1.26219vw;
      margin: 1.09756vw 1.42683vw; } }
  @media (max-width: 1280px) {
    .QbKUoWaJuuiA2dA1NWRvD {
      width: 3.54135vw;
      height: 1.45448vw;
      margin: 1.26477vw 1.6442vw; } }
  @media (max-width: 767px) {
    .QbKUoWaJuuiA2dA1NWRvD {
      width: 56px;
      height: 23px;
      margin: 20px 26px; } }
  .QbKUoWaJuuiA2dA1NWRvD._3MPTR4qphr0Tju3NZJ15NB {
    background-color: rgba(62, 187, 82, 0.4); }
  .QbKUoWaJuuiA2dA1NWRvD ._3BnmZVyE6i_r1KbrpAzj-X {
    display: block;
    border-radius: 50%;
    width: 2.2694vw;
    height: 2.2694vw;
    top: -0.36603vw;
    transition: all 0.5s ease;
    cursor: pointer;
    position: absolute;
    background-color: #d64949; }
    @media (max-width: 1024px) {
      .QbKUoWaJuuiA2dA1NWRvD ._3BnmZVyE6i_r1KbrpAzj-X {
        width: 1.70122vw;
        height: 1.70122vw;
        top: -0.27439vw; } }
    @media (max-width: 1280px) {
      .QbKUoWaJuuiA2dA1NWRvD ._3BnmZVyE6i_r1KbrpAzj-X {
        width: 1.96039vw;
        height: 1.96039vw;
        top: -0.31619vw; } }
    @media (max-width: 767px) {
      .QbKUoWaJuuiA2dA1NWRvD ._3BnmZVyE6i_r1KbrpAzj-X {
        width: 31px;
        height: 31px;
        top: -5px; } }
    .QbKUoWaJuuiA2dA1NWRvD ._3BnmZVyE6i_r1KbrpAzj-X._3GDhSJdgfnEn42JU9w0FcM {
      right: 2.12299vw;
      -webkit-transform: translateX(100%);
              transform: translateX(100%);
      background-color: #3ebb52; }
      @media (max-width: 1024px) {
        .QbKUoWaJuuiA2dA1NWRvD ._3BnmZVyE6i_r1KbrpAzj-X._3GDhSJdgfnEn42JU9w0FcM {
          right: 1.59146vw; } }
      @media (max-width: 1280px) {
        .QbKUoWaJuuiA2dA1NWRvD ._3BnmZVyE6i_r1KbrpAzj-X._3GDhSJdgfnEn42JU9w0FcM {
          right: 1.83391vw; } }
      @media (max-width: 767px) {
        .QbKUoWaJuuiA2dA1NWRvD ._3BnmZVyE6i_r1KbrpAzj-X._3GDhSJdgfnEn42JU9w0FcM {
          right: 29px; } }

._2Dp-JGJ-QuFJnaogxCB81O {
  margin-left: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  ._2Dp-JGJ-QuFJnaogxCB81O p {
    font-family: Raleway;
    font-style: normal;
    font-weight: 500;
    text-align: right;
    line-height: 1.75695vw;
    font-size: 1.46413vw;
    color: #e0dede; }
    @media (max-width: 1024px) {
      ._2Dp-JGJ-QuFJnaogxCB81O p {
        line-height: 1.31707vw;
        font-size: 1.09756vw; } }
    @media (max-width: 1280px) {
      ._2Dp-JGJ-QuFJnaogxCB81O p {
        line-height: 1.51772vw;
        font-size: 1.26477vw; } }
    @media (max-width: 767px) {
      ._2Dp-JGJ-QuFJnaogxCB81O p {
        line-height: 24px;
        font-size: 20px; } }

.TbTiYkO2BAvp5CDH2MmyF {
  color: #35e89f;
  border: 1px solid currentColor;
  text-transform: uppercase;
  margin-bottom: 2.19619vw;
  padding: 0.73206vw 1.75695vw;
  font-size: 1.46413vw; }
  @media (max-width: 1024px) {
    .TbTiYkO2BAvp5CDH2MmyF {
      margin-bottom: 1.64634vw;
      padding: 0.54878vw 1.31707vw;
      font-size: 1.09756vw; } }
  @media (max-width: 1280px) {
    .TbTiYkO2BAvp5CDH2MmyF {
      margin-bottom: 1.89715vw;
      padding: 0.63238vw 1.51772vw;
      font-size: 1.26477vw; } }
  @media (max-width: 767px) {
    .TbTiYkO2BAvp5CDH2MmyF {
      margin-bottom: 30px;
      padding: 10px 24px;
      font-size: 20px; } }

._1EYcLodkYjdkzkjf4eC6Qs {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  list-style: none; }
