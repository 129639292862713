._2qKxvdvwzFaCNk5e4NdM2z {
  background-color: #426b5c;
  background: linear-gradient(to right, #426b5c 0%, #374b43 100%);
  color: #e6e8e6;
  min-height: 11.63982vw;
  padding: 1.68375vw 1.53734vw 0vw 1.83016vw; }
  @media (max-width: 1024px) {
    ._2qKxvdvwzFaCNk5e4NdM2z {
      min-height: 8.72561vw;
      padding: 1.26219vw 1.15244vw 0vw 1.37195vw; } }
  @media (max-width: 1280px) {
    ._2qKxvdvwzFaCNk5e4NdM2z {
      min-height: 10.0549vw;
      padding: 1.45448vw 1.32801vw 0vw 1.58096vw; } }
  @media (max-width: 767px) {
    ._2qKxvdvwzFaCNk5e4NdM2z {
      min-height: 159px;
      padding: 23px 21px 0px 25px; } }

._3ETL818H9q8stWgqCRVuYm {
  font-family: "Raleway", sans-serif;
  font-weight: 500;
  font-size: 1.0981vw;
  line-height: 1.75695vw; }
  @media (max-width: 1024px) {
    ._3ETL818H9q8stWgqCRVuYm {
      font-size: 0.82317vw;
      line-height: 1.31707vw; } }
  @media (max-width: 1280px) {
    ._3ETL818H9q8stWgqCRVuYm {
      font-size: 0.94858vw;
      line-height: 1.51772vw; } }
  @media (max-width: 767px) {
    ._3ETL818H9q8stWgqCRVuYm {
      font-size: 15px;
      line-height: 24px; } }

._2Kls9puWftM9CkRA2FDcOq {
  font-family: "Raleway", sans-serif;
  font-weight: 400;
  letter-spacing: -0.03em;
  text-transform: uppercase;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #e6e8e6;
  font-size: 2.92826vw; }
  @media (max-width: 1024px) {
    ._2Kls9puWftM9CkRA2FDcOq {
      font-size: 2.19512vw; } }
  @media (max-width: 1280px) {
    ._2Kls9puWftM9CkRA2FDcOq {
      font-size: 2.52953vw; } }
  @media (max-width: 767px) {
    ._2Kls9puWftM9CkRA2FDcOq {
      font-size: 40px; } }

._1H_XQslyNCFA_u0EXhLvHA {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

._27yRotx8rmD5WGHW-LUVuV {
  text-transform: uppercase; }

._3NLYIAM9MM3FryzXFTVaJL {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-size: 1.61054vw;
  line-height: 1.83016vw;
  padding-right: 1.46413vw;
  padding-bottom: 1.02489vw; }
  @media (max-width: 1024px) {
    ._3NLYIAM9MM3FryzXFTVaJL {
      font-size: 1.20732vw;
      line-height: 1.37195vw;
      padding-right: 1.09756vw;
      padding-bottom: 0.76829vw; } }
  @media (max-width: 1280px) {
    ._3NLYIAM9MM3FryzXFTVaJL {
      font-size: 1.39124vw;
      line-height: 1.58096vw;
      padding-right: 1.26477vw;
      padding-bottom: 0.88534vw; } }
  @media (max-width: 767px) {
    ._3NLYIAM9MM3FryzXFTVaJL {
      font-size: 22px;
      line-height: 25px;
      padding-right: 20px;
      padding-bottom: 14px; } }

.ibZaibzykpTG2QAZGQZit {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  letter-spacing: -0.03em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #e6e8e6;
  font-size: 1.0981vw;
  line-height: 1.75695vw;
  max-width: 12.4451vw; }
  @media (max-width: 1024px) {
    .ibZaibzykpTG2QAZGQZit {
      font-size: 0.82317vw;
      line-height: 1.31707vw;
      max-width: 9.32927vw; } }
  @media (max-width: 1280px) {
    .ibZaibzykpTG2QAZGQZit {
      font-size: 0.94858vw;
      line-height: 1.51772vw;
      max-width: 10.75052vw; } }
  @media (max-width: 767px) {
    .ibZaibzykpTG2QAZGQZit {
      font-size: 15px;
      line-height: 24px;
      max-width: 170px; } }
  .ibZaibzykpTG2QAZGQZit:hover {
    color: #35E89F;
    text-decoration: underline; }
