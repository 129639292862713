._2OnrDVDqOvNiZx5uQ-bsaE {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #383638;
  min-height: 29.28258vw;
  margin-bottom: 5.12445vw;
  padding: 5.12445vw; }
  @media (max-width: 1024px) {
    ._2OnrDVDqOvNiZx5uQ-bsaE {
      min-height: 21.95121vw;
      margin-bottom: 3.84146vw;
      padding: 3.84146vw; } }
  @media (max-width: 1280px) {
    ._2OnrDVDqOvNiZx5uQ-bsaE {
      min-height: 25.29534vw;
      margin-bottom: 4.42669vw;
      padding: 4.42669vw; } }
  @media (max-width: 767px) {
    ._2OnrDVDqOvNiZx5uQ-bsaE {
      min-height: 400px;
      margin-bottom: 70px;
      padding: 70px; } }

._1RPg-WdsEcWY1TI5tarH7M {
  color: #4f695f;
  font-weight: 500;
  text-align: center;
  margin-bottom: 5.85652vw;
  font-size: 1.90337vw; }
  @media (max-width: 1024px) {
    ._1RPg-WdsEcWY1TI5tarH7M {
      margin-bottom: 4.39024vw;
      font-size: 1.42683vw; } }
  @media (max-width: 1280px) {
    ._1RPg-WdsEcWY1TI5tarH7M {
      margin-bottom: 5.05907vw;
      font-size: 1.6442vw; } }
  @media (max-width: 767px) {
    ._1RPg-WdsEcWY1TI5tarH7M {
      margin-bottom: 80px;
      font-size: 26px; } }

.TAjJ2VkNDFeBOuG83AjV8 {
  margin-bottom: 1.83016vw; }
  @media (max-width: 1024px) {
    .TAjJ2VkNDFeBOuG83AjV8 {
      margin-bottom: 1.37195vw; } }
  @media (max-width: 1280px) {
    .TAjJ2VkNDFeBOuG83AjV8 {
      margin-bottom: 1.58096vw; } }
  @media (max-width: 767px) {
    .TAjJ2VkNDFeBOuG83AjV8 {
      margin-bottom: 25px; } }
  .TAjJ2VkNDFeBOuG83AjV8 > svg {
    width: 2.48902vw;
    height: 4.31918vw; }
    @media (max-width: 1024px) {
      .TAjJ2VkNDFeBOuG83AjV8 > svg {
        width: 1.86585vw;
        height: 3.2378vw; } }
    @media (max-width: 1280px) {
      .TAjJ2VkNDFeBOuG83AjV8 > svg {
        width: 2.1501vw;
        height: 3.73106vw; } }
    @media (max-width: 767px) {
      .TAjJ2VkNDFeBOuG83AjV8 > svg {
        width: 34px;
        height: 59px; } }
